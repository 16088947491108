
// import React, { useRef, useState } from 'react';
// import Header from '../src/Components/Header';
// import SecondaryBanner from '../src/Components/SecondaryBanner';
// import Footer from '../src/Components/Footer';
// import Slider from 'react-slick';
// import Accordian from '../src/Components/Accordian';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import './Product.css';

// const Product = () => {

//   const headingText = "Our Product";
//   const subTextContent = "Home / Product";
//   const sliderRef = useRef(null);

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     draggable: true // Enable dragging
//   };

//   return (
//     <div>
//       <Header/>
//       <SecondaryBanner heading={headingText} subText={subTextContent}/>
//       <div className='container mt-5'>
//         <div className='row'>
//           <div className="heading">We Have Amazing Product For<br /> Your Company Needs</div>
//           <div className="sub-text mt-4">Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam</div>
//         </div>
//       </div>
//       <div className="slider-wrapper">
//         <Slider ref={sliderRef} {...settings}>
//           <Accordian />
//           <Accordian />
//           <Accordian />
//         </Slider>
//         {/* <div className="button-container">
//           <button className="prev-button" onClick={() => sliderRef.current.slickPrev()}><i class="fa-solid fa-chevron-left"></i></button>
//           <button className="next-button" onClick={() => sliderRef.current.slickNext()}><i class="fa-solid fa-chevron-right"></i></button>
//         </div> */}
//       </div>
//       <Footer />
//     </div>
//   );
// }

// export default Product;





import React, { useRef } from 'react';
import Header from '../src/Components/Header';
import SecondaryBanner from '../src/Components/SecondaryBanner';
import Footer from '../src/Components/Footer';
import Slider from 'react-slick';
import Accordian from '../src/Components/Accordian';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Product.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


// Custom Prev Arrow component
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button className={`${className} custom-prev-arrow`} style={{ ...style, left: '-50px' }} onClick={onClick}>
      Prev
    </button>
  );
};

// Custom Next Arrow component
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button className={`${className} custom-next-arrow`} style={{ ...style, right: '-50px' }} onClick={onClick}>
      Next
    </button>
  );
};

const Product = () => {
  const headingText = "Our Product";
  const headingText1 = "Our Productee";
  const subTextContent = "Home / Product";
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true, // Make the slider loop continuously
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    variableWidth: false,
    centerMode: true,
    // adaptiveHeight: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    initialSlide: 1 // Display the center slide first
  };

  return (
    <div>
      <SecondaryBanner heading={headingText} subText={subTextContent} />
      <div className='container mt-5 pt-5'>
        <div className='row'>
          <div className="heading">We Have Amazing Product For<br /> Your Company Needs</div>
          <div className="sub-text mt-4">Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam</div>
        </div>
      </div>
      <div className="slider-wrapper mt-5">
        {/* <Carousel autoPlay={true} showArrows={true} showThumbs={false} showStatusß={false} ref={sliderRef} >
          <Accordian heading={headingText} keyvalue="0"/>
          <div></div>
          <Accordian heading={headingText1} keyvalue="1"/>
        </Carousel> */}
        <Slider ref={sliderRef} {...settings}>
          <Accordian />
          <Accordian />
          <Accordian />
        </Slider>
          <div className="button-container">
           <button className="prev-button" onClick={() => sliderRef.current.slickPrev()}><i class="fa-solid fa-angles-left"></i></button>
           <button className="next-button" onClick={() => sliderRef.current.slickNext()}><i class="fa-solid fa-angles-right"></i></button>
         </div>
      </div>
      <Footer />
    </div>
  );
}

export default Product;
