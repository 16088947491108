import React, { useReducer, useRef, useState } from "react";
import "./ContactUs.css";
import subtract from "../src/assets/images/subtract.svg";

const ContactUs = ({ property1 }) => {
  const reducer = (state, action) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "mouse_enter":
        return {
          ...state,
          property1: "variant-2",
        };

      case "mouse_leave":
        return {
          ...state,
          property1: "default",
        };
    }

    return state;
  };

  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  return (
    <button
      className={`button-8 mt-3`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <div className={`overlap-group-2 property-1-2-${state.property1}`}>
        <div className="text-wrapper-3">Contact us</div>
        {state.property1 === "default" && (
          <img className="subtract-2" alt="Subtract" src={subtract} />
        )}
      </div>
    </button>
  );
};

export default ContactUs;
