import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './News.css';
import news from '../assets/images/newsimage.png';


export default function AboutDescribe() {
  useEffect(() => {
    // Initialize AOS
    AOS.init();

    return () => {
      AOS.refresh(); // Remove if you don't want to refresh on unmount
    };
  }, []);
  return (
    <div>
    <div className="container mt-5 pt-2">
        <div className="row">
        <div className="heading">Blog / News</div>
        <div className="sub-text mt-2">Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam</div>
        </div>
        </div>
        <div className="container mt-5 pt-5 custom">
            <div className="row news-box" data-aos="fade-up" data-aos-duration="1200">
              <div className="col-lg-6 col-md-6 col-sm-12">
              <img src={news} alt="photo" className="full-width"/> 
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="news-heading mt-5">AI product development is being held back by data engineering. It’s time to do something about it</div>
                <div className="news-sub mt-4">Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante.</div>
                </div>
            </div>
        </div>   
    </div>
  );
}
