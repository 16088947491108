import React, { useEffect, useReducer } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./NewsLetter.css";
import line from "../assets/images/line-89-1.svg";
import subtract from "../assets/images/subtract.svg";
import vector1 from "../assets/images/vector-41.svg";
import vector2 from "../assets/images/vector-42.svg";
import vector3 from "../assets/images/vector-43.svg";
import vector4 from "../assets/images/vector-44.svg";

export default function NewsLetter() {
  const reducer = (state, action) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "mouse_enter":
        return {
          ...state,
          property1: "variant-2",
        };

      case "mouse_leave":
        return {
          ...state,
          property1: "default",
        };
    }

    return state;
  };

  const [state, dispatch] = useReducer(reducer, {
    property1: "default",
  });

  useEffect(() => {
    // Initialize AOS
    AOS.init();

    return () => {
      AOS.refresh(); // Remove if you don't want to refresh on unmount
    };
  }, []);
  return (
    <div>
      <div className="container mt-5 custom">
        <div className="frame-271">
          <div className="frame-272">
            <div className="text-wrapper-160">Join Our Newsletter</div>
            <p className="text-wrapper-161">Lorem ipsum dolor sit amet consectetur adipiscing elit</p>
          </div>
          <div className="frame-263">
            <div className="group-121">
              <img className="line-10" alt="Line" src={line} />
              <div className="enter-your-email">Enter Your Email Address</div>
            </div>
            <button
              className={`button-6`}
              onMouseLeave={() => {
                dispatch("mouse_leave");
              }}
              onMouseEnter={() => {
                dispatch("mouse_enter");
              }}
            >
              <div
                className={`overlap-group-5 property-1-7-${state.property1}`}
              >
                <div className="text-wrapper-6">Submit</div>
                {state.property1 === "default" && (
                  <img
                    className="subtract-5"
                    alt="Subtract"
                    src={subtract}
                  />
                )}
              </div>
            </button>
          </div>

          <div class="animation-features-item vector-30">
            <div class="animation-features-path">
              <span class="animation-features-ball-left"></span>
              <img class="path-desktop" src={vector2}></img>
            </div>
          </div>

          <div class="animation-features-item vector-31">
            <div class="animation-features-path">
              <span class="animation-features-ball-left"></span>
              <img class="path-desktop" src={vector4}></img>
            </div>
          </div>

          <div class="animation-features-item vector-28">
            <div class="animation-features-vertical">
              <span class="animation-features-ball-down"></span>
              <img class="path-desktop" src={vector1}></img>
            </div>
          </div>

          <div class="animation-features-item vector-29">
            <div class="animation-features-vertical">
              <span class="animation-features-ball-up"></span>
              <img class="path-desktop" src={vector3}></img>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
}
