import React, { useEffect } from 'react';
import './Client.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import c1 from '../assets/images/1.png';
import c2 from '../assets/images/2.png';
import c3 from '../assets/images/3.png';
import c4 from '../assets/images/4.png';
import c5 from '../assets/images/5.png';
import c6 from '../assets/images/6.png';
import c7 from '../assets/images/7.png';
import c8 from '../assets/images/8.png';
import c9 from '../assets/images/9.png';
import c10 from '../assets/images/10.png';
import c11 from '../assets/images/11.png';
import c12 from '../assets/images/12.png';
import c13 from '../assets/images/13.png';
import c14 from '../assets/images/14.png';
import c15 from '../assets/images/15.png';
import c16 from '../assets/images/16.png';
import c17 from '../assets/images/17.png';
import c18 from '../assets/images/18.png';
import c19 from '../assets/images/19.png';
import c20 from '../assets/images/20.png';
import c21 from '../assets/images/21.png';
import c22 from '../assets/images/22.png';
import c23 from '../assets/images/23.png';
import c24 from '../assets/images/24.png';
import c25 from '../assets/images/25.png';
import c26 from '../assets/images/26.png';
import c27 from '../assets/images/27.png';
import c28 from '../assets/images/28.png';
import c29 from '../assets/images/29.png';
import c30 from '../assets/images/30.png';
import c31 from '../assets/images/31.png';
import c32 from '../assets/images/32.png';
import c33 from '../assets/images/33.png';

export default function Services() {
    useEffect(() => {
        // Initialize AOS
        AOS.init();

        return () => {
          AOS.refresh(); // Remove if you don't want to refresh on unmount
        };
      }, []);
  return (
    <div>
     <div className="container mt-3">
        <div className="row">
        <div className="new-heading">Our Clients</div>
        <div className="sub-text mt-3">Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam</div>
        </div>
        </div> 
        {/* <div className='container mt-5'>
<div className='row mt-4'>
<div id="main">
  <div className='border-logobox'>
  <img src={c1} alt="photo" className="img-width-s mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c2} alt="photo" className="img-width-s mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c3} alt="photo" className="img-width-s mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c4} alt="photo" className="img-width-s mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c5} alt="photo" className="img-width-s mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c6} alt="photo" className="img-width-s mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c7} alt="photo" className="img-width-s mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c8} alt="photo" className="img-width-s mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c22} alt="photo" className="img-width-s mt-1" />
  </div>
</div>
</div>
</div>
<div className='container'>
<div className='row mt-2'>
<div id="main-2">
  <div className='border-logobox'>
  <img src={c9} alt="photo" className="img-width-s mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c10} alt="photo" className="img-width-s mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c11} alt="photo" className="img-width-s mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c12} alt="photo" className="img-width-s mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c13} alt="photo" className="img-width-s mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c14} alt="photo" className="img-width-m mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c15} alt="photo" className="img-width-m mt-1" />
  </div>
</div>
</div>
</div>
<div className='container'>
<div className='row mt-2'>
<div id="main-3">
  <div className='border-logobox'>
  <img src={c16} alt="photo" className="img-width-s mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c17} alt="photo" className="img-width-m mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c18} alt="photo" className="img-width-m mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c19} alt="photo" className="img-width-m mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c20} alt="photo" className="img-width-m mt-1" />
  </div>
</div>
</div>
</div>
<div className='container'>
<div className='row mt-2'>
<div id="main-3">
  <div className='border-logobox'>
  <img src={c21} alt="photo" className="img-width-s mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c33} alt="photo" className="img-width-m mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c23} alt="photo" className="img-width-m mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c24} alt="photo" className="img-width-m mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c25} alt="photo" className="img-width-m mt-1" />
  </div>
</div>
</div>
</div>
<div className='container'>
<div className='row mt-2'>
<div id="main-4">
  <div className='border-logobox'>
  <img src={c26} alt="photo" className="img-width-m mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c27} alt="photo" className="img-width-m mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c28} alt="photo" className="img-width-m mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c29} alt="photo" className="img-width-m mt-1" />
  </div>
</div>
</div>
</div>
<div className='container'>
<div className='row mt-2'>
<div id="main-5">
  <div className='border-logobox'>
  <img src={c31} alt="photo" className="img-width-m mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c32} alt="photo" className="img-width-m mt-1" />
  </div>
  <div className='border-logobox'>
  <img src={c33} alt="photo" className="img-width-m mt-1" />
  </div>
</div>
</div>
</div>   */}
<div class="container">
 <div class="slider mt-5 mb-3 pt-2">  
    <div class="slider-track mt-4">
        <div className='scroll-box'>
        <img src={c1} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c2} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c3} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c4} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c5} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c6} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c7} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c8} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c9} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c10} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c11} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c12} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c13} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c14} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c15} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c32} class="slide" alt='client'/>
        </div>
    </div>
   
   </div>
   <div class="slider mt-1">
   
    <div class="slider-track-2 px-5">
        <div className='scroll-box'>
        <img src={c16} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c17} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c18} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c19} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c20} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c21} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c22} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c23} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c24} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c25} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c26} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c27} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c28} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c29} class="slide" alt='client'/>
        </div>
        <div className='scroll-box'>
        <img src={c30} class="slide" alt='client'/>
        </div>   <div className='scroll-box'>
        <img src={c31} class="slide" alt='client'/>
        </div>
        </div>
 
   </div>
   </div>
   
    </div>
  );
}
