import './AboutDescribe.css';
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function AboutDescribe({ title, description, imageSrc, mainBoxHeight, smallScreenHeight, mbvalue, customClass}) {
  const [adjustedHeight, setAdjustedHeight] = useState(mainBoxHeight);
  useEffect(() => {
    // Initialize AOS
    AOS.init();

    return () => {
      AOS.refresh(); // Remove if you don't want to refresh on unmount
    };
  }, []);
    // Adjust height based on screen size
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <= 576) {
          setAdjustedHeight(smallScreenHeight);
        } else {
          setAdjustedHeight(mainBoxHeight);
        }
      };
  
      // Call handleResize on initial render and when window is resized
      handleResize();
      window.addEventListener('resize', handleResize);
  
      // Clean up event listener
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [mainBoxHeight, smallScreenHeight]);

  return (
    <div>
      <div className="container mt-4">
        <div className="row box-about" data-aos="fade-up" data-aos-duration="1200">
          <div className="overall mt-1">
            <div className="main-box" style={{ height: adjustedHeight }}>
              <img src={imageSrc} alt="photo" className="full-width object border-img pt-4" />
            </div>
            <div className={`part ${customClass}`} >
              {title}
            </div>
          </div>
          <div>
            {description.map((text, index) => (
              <div key={index} className="sub-about mt-3" style={{ marginBottom: mbvalue }}>
                {text}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
