import React, { useEffect, useReducer } from "react";
import "./BlogList.css";
import AOS from "aos";
import sampleblog from "../assets/images/blogsample.png";
import "aos/dist/aos.css";
import subtract from "../assets/images/subtract.svg";

export default function BlogList() {
  const reducer = (state, action) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "mouse_enter":
        return {
          ...state,
          property1: "variant-2",
        };

      case "mouse_leave":
        return {
          ...state,
          property1: "default",
        };
    }

    return state;
  };

  const [state, dispatch] = useReducer(reducer, {
    property1: "default",
  });

  const paragraphText =
    "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque";
  useEffect(() => {
    // Initialize AOS
    AOS.init();

    return () => {
      AOS.refresh(); // Remove if you don't want to refresh on unmount
    };
  }, []);
  return (
    <div>
      <div className="container mt-5 pt-5">
        <div className="row">
          <div className="new-heading">Blog / News</div>
          <div className="sub-text">
            Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
            mi. Aliquam
          </div>
        </div>
      </div>
      <div className="container mt-5 pt-3 custom">
        <div className="row mt-3">
          <div
            className="col-lg-4 col-md-12 col-sm-12"
            data-aos="flip-right"
            data-aos-duration="1500"
          >
            <div className="blog-box zoom-effect">
              <div className="blog-image">
                <img src={sampleblog} alt="photo" className="full-width" />
              </div>
              <div className="blog-heading mt-3">
                Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                massa mi. Aliquam in hendrerit urna. Pellentesque
              </div>
              <div className="blog-text mt-3 limited-lines">
                {paragraphText}
              </div>
              <div className="load mt-3">See More</div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-12 col-sm-12 space-s"
            data-aos="flip-right"
            data-aos-duration="1500"
          >
            <div className="blog-box zoom-effect">
              <div className="blog-image">
                <img src={sampleblog} alt="photo" className="full-width" />
              </div>
              <div className="blog-heading mt-3">
                Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                massa mi. Aliquam in hendrerit urna. Pellentesque
              </div>
              <div className="blog-text mt-3 limited-lines">
                {paragraphText}
              </div>
              <div className="load mt-3">See More</div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-12 col-sm-12 space-s"
            data-aos="flip-right"
            data-aos-duration="1500"
          >
            <div className="blog-box zoom-effect">
              <div className="blog-image">
                <img src={sampleblog} alt="photo" className="full-width" />
              </div>
              <div className="blog-heading mt-3">
                Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                massa mi. Aliquam in hendrerit urna. Pellentesque
              </div>
              <div className="blog-text mt-3 limited-lines">
                {paragraphText}
              </div>
              <div className="load mt-3">See More</div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div
            className={`button-9`}
            onMouseLeave={() => {
              dispatch("mouse_leave");
            }}
            onMouseEnter={() => {
              dispatch("mouse_enter");
            }}
          >
            <div className={`overlap-group-2 property-1-2-${state.property1}`}>
              <div className="text-wrapper-3">See More</div>
              {state.property1 === "default" && (
                <img className="subtract-2" alt="Subtract" src={subtract} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
