// import React, { useEffect, useReducer } from "react";
// import "./Services.css";
// import s1 from "../assets/images/s1.png";
// import s2 from "../assets/images/s2.png";
// import s3 from "../assets/images/s3.png";
// import s4 from "../assets/images/s4.png";
// import s5 from "../assets/images/s5.png";
// import s6 from "../assets/images/s6.png";
// import robot from "../assets/images/robot.png";
// import overall from "../assets/images/overall.png";
// import highlight from "../assets/images/highlight.png";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import subtract from "../assets/images/subtract.svg";
// import maskgroup15 from "../assets/images/mask-group-15.png";
// import maskgroup16 from "../assets/images/mask-group-16.png";
// import maskgroup17 from "../assets/images/mask-group-17.png";
// import maskgroup18 from "../assets/images/mask-group-18.png";
// import maskgroup19 from "../assets/images/mask-group-19.png";
// import maskgroup20 from "../assets/images/mask-group-20.png";
// import maskgroup22 from "../assets/images/mask-group-22.png";
// import maskgroup24 from "../assets/images/mask-group-24.png";
// import maskgroup26 from "../assets/images/mask-group-26.png";

// export default function Services() {
//   const reducer = (state, action) => {
//     // eslint-disable-next-line default-case
//     switch (action) {
//       case "mouse_enter":
//         return {
//           ...state,
//           property1: "variant-2",
//         };

//       case "mouse_leave":
//         return {
//           ...state,
//           property1: "default",
//         };
//     }

//     return state;
//   };

//   const [state, dispatch] = useReducer(reducer, {
//     property1: "default",
//   });

//   useEffect(() => {
//     // Initialize AOS
//     AOS.init();

//     return () => {
//       AOS.refresh();
//     };
//   }, []);
//   return (
//     <div>
//       <section className="service-container">
//         <div className="container mt-3">
//           <div className="row">
//             <div className="new-heading">Our Services</div>
//             <div className="sub-text mt-3">
//               Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
//               mi. Aliquam
//             </div>
//           </div>
//         </div>
//         <div className="container mt-5 mb-5 custom">
//           <div className="row no-gap-cols">
//             <div className="col-lg-3 col-md-12 col-sm-12">
//               <div className="response">
//                 <div className="service-box sb-1">Security</div>
//                 <div className="service-box sb-2">Networking</div>
//                 <div className="service-box sb-3">DataRecovery</div>
//                 <div className="service-box sb-4">Artificial Intelligence</div>
//               </div>
//             </div>
//             <div className="col-lg-6 col-md-0 col-sm-0 col-0">
//               <img
//                 src={overall}
//                 alt="photo"
//                 className="full-width img-overall"
//               />
//             </div>
//             <div className="col-lg-3 col-md-12 col-sm-12">
//               <div className="response">
//                 <div className="service-box sb-1">Software</div>
//                 <div className="service-box sb-2">Hardware</div>
//                 <div className="service-box sb-3">Marketing</div>
//                 <div className="service-box sb-4">IT Consulting</div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="container mt-5 pt-5">
//           <div className="row mt-4 custom">
//             <div className="frame-123">
//               <div className="frame-124">
//                 <div className="frame-125">
//                   <div className="text-wrapper-87">Our Services</div>
//                   <p className="text-wrapper-88">
//                     Lorem ipsum dolor sit amet consectetur adipiscing elit
//                   </p>
//                 </div>
//                 <div
//                   className={`property-default-wrapper property-1-0-${state.property1}`}
//                   onMouseLeave={() => {
//                     dispatch("mouse_leave");
//                   }}
//                   onMouseEnter={() => {
//                     dispatch("mouse_enter");
//                   }}
//                 >
//                   <div className="div">
//                     <div className="text-wrapper-2">See Our Services</div>
//                     {state.property1 === "default" && (
//                       <img className="img" alt="Subtract" src={subtract} />
//                     )}
//                   </div>
//                 </div>
//               </div>
//               {/* <div className="group-62">
//                 <div className="group-63">
//                   <div className="overlap-12">
//                     <div className="ellipse-18" />
//                     <div className="ellipse-18" />
//                     <div className="ellipse-19" />
//                     <div className="group-64">
//                       <div className="overlap-group-16">
//                         <img
//                           className="mask-group-16"
//                           alt="Mask group"
//                           src={maskgroup15}
//                         />
//                         <img
//                           className="mask-group-17"
//                           alt="Mask group"
//                           src={maskgroup16}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="group-65">
//                   <div className="overlap-12">
//                     <div className="ellipse-18" />
//                     <div className="ellipse-18" />
//                     <div className="ellipse-19" />
//                     <div className="group-64">
//                       <div className="overlap-group-17">
//                         <img
//                           className="mask-group-18"
//                           alt="Mask group"
//                           src={maskgroup17}
//                         />
//                         <img
//                           className="mask-group-19"
//                           alt="Mask group"
//                           src={maskgroup18}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="group-66">
//                   <div className="overlap-12">
//                     <div className="ellipse-18" />
//                     <div className="ellipse-19" />
//                     <img
//                       className="mask-group-20"
//                       alt="Mask group"
//                       src={maskgroup19}
//                     />
//                     <img
//                       className="group-64"
//                       alt="Mask group"
//                       src={maskgroup20}
//                     />
//                   </div>
//                 </div>
//                 <div className="group-67">
//                   <div className="overlap-12">
//                     <div className="ellipse-18" />
//                     <div className="ellipse-19" />
//                     <div className="group-64">
//                       <div className="overlap-group-18">
//                         <img
//                           className="mask-group-21"
//                           alt="Mask group"
//                           src={maskgroup22}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="group-68">
//                   <div className="overlap-12">
//                     <div className="ellipse-18" />
//                     <div className="ellipse-19" />
//                     <div className="group-64">
//                       <div className="overlap-group-19">
//                         <img
//                           className="mask-group-21"
//                           alt="Mask group"
//                           src={maskgroup24}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="group-69">
//                   <div className="overlap-12">
//                     <div className="ellipse-18" />
//                     <div className="ellipse-19" />
//                     <div className="group-64">
//                       <div className="overlap-group-20">
//                         <img
//                           className="mask-group-21"
//                           alt="Mask group"
//                           src={maskgroup26}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div> */}
//             </div>
//           </div>
//         </div>
//         <div className="container mt-5 pt-5">
//           <div className="row mt-4">
//             <div className="new-heading">
//               Make Your Work Fast, Efficient
//               <br /> & Easy With AvtBot
//             </div>
//             <div className="sub-text mt-4">
//               Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
//               mi. Aliquam
//             </div>
//             <div>
//               <div
//                 className="img-main mt-3"
//                 data-aos="fade-down"
//                 data-aos-duration="1800"
//               >
//                 <img src={robot} alt="photo" className="full-width pt-4" />
//               </div>
//               <div className="highlight">
//                 <img src={highlight} alt="photo" className="full-width" />
//               </div>
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-lg-1"></div>
//             <div className="col-lg-10">
//               <div className="row">
//                 <div
//                   className="col-lg-3 col-md-6 col-sm-6 col-6"
//                   data-aos="fade-right"
//                   data-aos-duration="1000"
//                 >
//                   <div className="shine-btn">Measurement</div>
//                 </div>
//                 <div
//                   className="col-lg-3 col-md-6 col-sm-6 col-6"
//                   data-aos="fade-right"
//                   data-aos-duration="1000"
//                 >
//                   <div className="shine-btn">Measurement</div>
//                 </div>
//                 <div
//                   className="col-lg-3 col-md-6 col-sm-6 col-6 space-s"
//                   data-aos="fade-left"
//                   data-aos-duration="1000"
//                 >
//                   <div className="shine-btn">Measurement</div>
//                 </div>
//                 <div
//                   className="col-lg-3 col-md-6 col-sm-6 col-6 space-s"
//                   data-aos="fade-left"
//                   data-aos-duration="1000"
//                 >
//                   <div className="shine-btn">Measurement</div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-1"></div>
//           </div>
//           <div className="row mt-3 mb-3">
//             <div className="col-lg-1"></div>
//             <div className="col-lg-10">
//               <div className="row">
//                 <div
//                   className="col-lg-3 col-md-6 col-sm-6 col-6"
//                   data-aos="fade-right"
//                   data-aos-duration="1500"
//                 >
//                   <div className="shine-btn">Measurement</div>
//                 </div>
//                 <div
//                   className="col-lg-3 col-md-6 col-sm-6 col-6"
//                   data-aos="fade-right"
//                   data-aos-duration="1500"
//                 >
//                   <div className="shine-btn">Measurement</div>
//                 </div>
//                 <div
//                   className="col-lg-3 col-md-6 col-sm-6 col-6 space-s"
//                   data-aos="fade-left"
//                   data-aos-duration="1500"
//                 >
//                   <div className="shine-btn">Measurement</div>
//                 </div>
//                 <div
//                   className="col-lg-3 col-md-6 col-sm-6 col-6 space-s"
//                   data-aos="fade-left"
//                   data-aos-duration="1500"
//                 >
//                   <div className="shine-btn">Measurement</div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-1"></div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// }



import React, { useEffect, useReducer } from "react";
import './Services.css';
import s1 from '../assets/images/s1.png';
import s2 from '../assets/images/s2.png';
import s3 from '../assets/images/s3.png';
import s4 from '../assets/images/s4.png';
import s5 from '../assets/images/s5.png';
import s6 from '../assets/images/s6.png';
import subtract from "../assets/images/subtract.svg";
import robot from '../assets/images/robot.png';
import overall from '../assets/images/logo-middle.png';
import horizontal from '../assets/images/horizontal.svg'
import vertical from '../assets/images/vertical.svg';
import highlight from '../assets/images/highlight.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import maskgroup15 from "../assets/images/mask-group-15.png";
import maskgroup16 from "../assets/images/mask-group-16.png";
import maskgroup17 from "../assets/images/mask-group-17.png";
import maskgroup18 from "../assets/images/mask-group-18.png";
import maskgroup19 from "../assets/images/mask-group-19.png";
import maskgroup20 from "../assets/images/mask-group-20.png";
import maskgroup22 from "../assets/images/mask-group-22.png";
import maskgroup24 from "../assets/images/mask-group-24.png";
import maskgroup26 from "../assets/images/mask-group-26.png";

export default function Services() {

  const reducer = (state, action) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "mouse_enter":
        return {
          ...state,
          property1: "variant-2",
        };

      case "mouse_leave":
        return {
          ...state,
          property1: "default",
        };
    }

    return state;
  };

  const [state, dispatch] = useReducer(reducer, {
    property1: "default",
  });
  useEffect(() => {
    // Initialize AOS
    AOS.init();

    return () => {
      AOS.refresh();
    };
  }, []);
  return (
    <div>
      <section className='service-container'>
        <div className="container mt-3">
          <div className="row">
            <div className="new-heading">Our Services</div>
            <div className="sub-text mt-3">Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam</div>
          </div>
        </div>
        <div className="container mt-5 mb-5 custom">
          <div className='row no-gap-cols'>

            <div className='col-lg-3 col-md-6 col-sm-6'>

              <div class="animation-features-input left-right-serv-con">

                <div class="animation-features-item">
                  <div class="shine-curve-btn">Security</div>
                  <div class="animation-features-path">
                    <span class="animation-features-ball-left"></span>
                    <img class="path-desktop" src={horizontal}></img>
                  </div>
                </div>

                <div class="animation-features-item">
                  <div class="shine-curve-btn">Networking</div>
                  <div class="animation-features-path">
                    <span class="animation-features-ball-left"></span>
                    <img class="path-desktop" src={horizontal}></img>
                  </div>
                </div>

                <div class="animation-features-item">
                  <div class="shine-curve-btn">Data Recovery</div>
                  <div class="animation-features-path">
                    <span class="animation-features-ball-left"></span>
                    <img class="path-desktop" src={horizontal}></img>
                  </div>
                </div>

              </div>

            </div>

            <div className='col-lg-6 col-md-0 col-sm-0 col-0'>
              <div class="serv-img-box"><img src={overall} alt="photo" /></div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6'>

              <div class="animation-features-output left-right-serv-con">

                <div class="animation-features-item">

                  <div class="animation-features-path">
                    <span class="animation-features-ball-right"></span>
                    <img class="path-desktop" src={horizontal}></img>
                  </div>
                  <div class="shine-curve-btn">Software</div>
                </div>

                <div class="animation-features-item">

                  <div class="animation-features-path">
                    <span class="animation-features-ball-right"></span>
                    <img class="path-desktop" src={horizontal}></img>
                  </div>
                  <div class="shine-curve-btn">Hardware</div>
                </div>

                <div class="animation-features-item">

                  <div class="animation-features-path">
                    <span class="animation-features-ball-right"></span>
                    <img class="path-desktop" src={horizontal}></img>
                  </div>
                  <div class="shine-curve-btn">Marketing</div>
                </div>



              </div>
            </div>

          </div>

          <div className='row no-gap-cols serv-bot-box '>

            <div className='col-lg-4 col-md-6 col-sm-6'>
              <div class="animation-features-input">
                <div class="shine-curve-btn">Artificial Intelligence</div>
              </div>
            </div>

            <div className='col-lg-4 col-md-0 col-sm-0 col-0 '>

              <div class="serv-img-box-bottom">
                <div class="animation-features-path">
                  <span class="animation-features-ball-left"></span>
                </div>
              </div>

            </div>

            <div className='col-lg-4 col-md-6 col-sm-6'>
              <div class="animation-features-output">
                <div class="shine-curve-btn">IT Consulting</div>
              </div>
            </div>

          </div>

        </div>
        <div className="container mt-5 pt-5">
          <div className="row mt-4 custom">
            <div className="frame-123 d-flex">
              <div className="frame-124">
                <div className="frame-125">
                  <div className="text-wrapper-87">Our Services</div>
                  <p className="text-wrapper-88">
                    Lorem ipsum dolor sit amet consectetur adipiscing elit
                  </p>
                </div>
                <div
                  className={`property-default-wrapper property-1-0-${state.property1}`}
                  onMouseLeave={() => {
                    dispatch("mouse_leave");
                  }}
                  onMouseEnter={() => {
                    dispatch("mouse_enter");
                  }}
                >
                  <div className="div">
                    <div className="text-wrapper-2">See Our Services</div>
                    {state.property1 === "default" && (
                      <img className="img" alt="Subtract" src={subtract} />
                    )}
                  </div>
                </div>
              </div>
              <div className="group-62">
                <div className="group-63">
                  <div className="overlap-12">
                    <div className="ellipse-18" />
                    <div className="ellipse-18" />
                    <div className="ellipse-19" />
                    <div className="group-64">
                      <div className="overlap-group-16">
                        <img
                          className="mask-group-16"
                          alt="Mask group"
                          src={maskgroup15}
                        />
                        <img
                          className="mask-group-17"
                          alt="Mask group"
                          src={maskgroup16}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-65">
                  <div className="overlap-12">
                    <div className="ellipse-18" />
                    <div className="ellipse-18" />
                    <div className="ellipse-19" />
                    <div className="group-64">
                      <div className="overlap-group-17">
                        <img
                          className="mask-group-18"
                          alt="Mask group"
                          src={maskgroup17}
                        />
                        <img
                          className="mask-group-19"
                          alt="Mask group"
                          src={maskgroup18}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-66">
                  <div className="overlap-12">
                    <div className="ellipse-18" />
                    <div className="ellipse-19" />
                    <img
                      className="mask-group-20"
                      alt="Mask group"
                      src={maskgroup19}
                    />
                    <img
                      className="group-64"
                      alt="Mask group"
                      src={maskgroup20}
                    />
                  </div>
                </div>
                <div className="group-67">
                  <div className="overlap-12">
                    <div className="ellipse-18" />
                    <div className="ellipse-19" />
                    <div className="group-64">
                      <div className="overlap-group-18">
                        <img
                          className="mask-group-21"
                          alt="Mask group"
                          src={maskgroup22}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-68">
                  <div className="overlap-12">
                    <div className="ellipse-18" />
                    <div className="ellipse-19" />
                    <div className="group-64">
                      <div className="overlap-group-19">
                        <img
                          className="mask-group-21"
                          alt="Mask group"
                          src={maskgroup24}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-69">
                  <div className="overlap-12">
                    <div className="ellipse-18" />
                    <div className="ellipse-19" />
                    <div className="group-64">
                      <div className="overlap-group-20">
                        <img
                          className="mask-group-21"
                          alt="Mask group"
                          src={maskgroup26}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5 pt-5 mb-5">
          <div className="row mt-4">
            <div className="new-heading">Make Your Work Fast, Efficient<br /> & Easy With AvtBot</div>
            <div className="sub-text mt-4">Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam</div>
            <div>
              <div className="img-main mt-3" data-aos="fade-down" data-aos-duration="1800">
                <img src={robot} alt="photo" className="full-width pt-4" />
              </div>
              <div className="highlight">
                <img src={highlight} alt="photo" className="full-width" />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-1'></div>
            <div className='col-lg-10'>
              <div className='row'>
                <div className='col-lg-3 col-md-6 col-sm-6 col-6' data-aos="fade-right" data-aos-duration="1000">
                  <div className='shine-btn'>Measurement</div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-6 col-6' data-aos="fade-right" data-aos-duration="1000">
                  <div className='shine-btn'>Measurement</div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-6 col-6 space-s' data-aos="fade-left" data-aos-duration="1000">
                  <div className='shine-btn'>Measurement</div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-6 col-6 space-s' data-aos="fade-left" data-aos-duration="1000">
                  <div className='shine-btn'>Measurement</div>
                </div>
              </div>
            </div>
            <div className='col-lg-1'></div>
          </div>
          <div className='row mt-3 mb-3'>
            <div className='col-lg-1'></div>
            <div className='col-lg-10'>
              <div className='row'>
                <div className='col-lg-3 col-md-6 col-sm-6 col-6' data-aos="fade-right" data-aos-duration="1500">
                  <div className='shine-btn'>Measurement</div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-6 col-6' data-aos="fade-right" data-aos-duration="1500">
                  <div className='shine-btn'>Measurement</div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-6 col-6 space-s' data-aos="fade-left" data-aos-duration="1500">
                  <div className='shine-btn'>Measurement</div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-6 col-6 space-s' data-aos="fade-left" data-aos-duration="1500">
                  <div className='shine-btn'>Measurement</div>
                </div>
              </div>
            </div>
            <div className='col-lg-1'></div>
          </div>
        </div>
      </section>
    </div>
  );
}
