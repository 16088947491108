import React, { useEffect, useReducer } from "react";
import "./Banner.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./Header";
import subtract from "../assets/images/subtract.svg";

export default function Banner() {
  const reducer = (state, action) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "mouse_enter":
        return {
          ...state,
          property1: "variant-2",
        };

      case "mouse_leave":
        return {
          ...state,
          property1: "default",
        };
    }

    return state;
  };

  const [state, dispatch] = useReducer(reducer, {
    property1: "default",
  });

  useEffect(() => {
    // Initialize AOS
    AOS.init();

    const handleScroll = () => {
      const banner = document.querySelector(".bg");
      const bannerTop = banner.getBoundingClientRect().top;
      const bannerBottom = banner.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      if (bannerTop < windowHeight && bannerBottom > 0) {
        banner.classList.add("zoomed");
      } else {
        banner.classList.remove("zoomed");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      AOS.refresh();
    };
  }, []);

  return (
    <div>
      <div className="bg">
        <video autoPlay muted loop className="video-bg">
          <source
            src={`${process.env.PUBLIC_URL}/videos/bg.mp4`}
            type="video/mp4"
          />
          {/* Add other video sources here if necessary */}
          Your browser does not support HTML5 video.
        </video>
        <div className="container-fluid box-banner">
          <Header />
          <div className="container mt-5 pt-5">
            <div className="row mt-5 pt-5">
              <div className="hide">
                <div className="heading">A Smart, Sustainable Future</div>
                <div className="sub-text mt-3">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                  massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                  sapien.Lorem ipsum dolor sit amet consectetur adipiscing elit
                  Ut et massa mi.
                </div>
                <div class="row justify-content-center">
                <div
                  className={`button-2`}
                  onMouseLeave={() => {
                    dispatch("mouse_leave");
                  }}
                  onMouseEnter={() => {
                    dispatch("mouse_enter");
                  }}
                >
                  <button
                    className={`overlap-group-3 property-1-4-${state.property1}`}
                  >
                    <span className="text-wrapper-4">Book Now</span>
                    {state.property1 === "default" && (
                      <img
                        className="subtract-3"
                        src={subtract}
                        alt="Subtract"
                      />
                    )}
                  </button>
                </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
