
import React, { useState, useEffect } from "react";
import Header from "./Components/Header";
import OurProducts from "./Components/OurProducts";
import Services from "./Components/Services";
import Banner from "./Components/Banner";
import '../src/Components/Sidebar.css';
import Footer from '../src/Components/Footer';
import Client from '../src/Components/Client';
import BlogList from './Components/BlogList';

const Home = () => {
  const [activeSection, setActiveSection] = useState(0);

  const scrollToSection = (index) => {
    const sectionId = `section-${index}`;
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const activeIndex = parseInt(entry.target.dataset.index);
            setActiveSection(activeIndex);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    // Observe each section individually
    [1, 2, 3, 4].forEach((index) => { // Start from 1 since 0 is the header
      const sectionId = `section-${index}`;
      const section = document.getElementById(sectionId);
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="container-fluid">  
      <div className="side hide-side" style={{ display: activeSection === 0 ? 'none' : 'block' }}>
        <div className="sidebar">
          <div className="signal-container">
            {[ 1, 2, 3, 4].map((index) => (
              <div
                key={index}
                className={`dot ${index === activeSection ? 'active-dot' : ''}`}
                onClick={() => scrollToSection(index)}
              ></div>
            ))}
          </div>
        </div>
        <div className="section-names">
          {[
            { name: "Services", index: 1 },
            { name: "Products", index: 2 },
            { name: "Clients", index: 3 },
            { name: "Blog", index: 4 }
          ].map(({ name, index }) => (
            <div
              key={index}
              className={`name-section ${index === activeSection ? 'active-section' : ''}`}
              onClick={() => scrollToSection(index)}
            >
              {name}
            </div>
          ))}
        </div>
      </div>
      <Banner />
      <div id="section-1" className="section" data-index="1"><Services /></div>
      <div id="section-2" className="section" data-index="2"><OurProducts /></div>
      <div id="section-3" className="section" data-index="3"><Client /></div>
      <div id="section-4" className="section" data-index="4"><BlogList /></div>
      <Footer />
    </div>
  );
};

export default Home;

