import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import './Accordian.css'
import crypto from '../assets/images/crypto.png';
import 'aos/dist/aos.css';
import productleave from '../assets/images/productleave.png';
import tab from '../assets/images/tab.png';
import view from '../assets/images/view.png';

export default function Accordian() {
  useEffect(() => {
    // Initialize AOS
    AOS.init();

    return () => {
      AOS.refresh(); // Remove if you don't want to refresh on unmount
    };
  }, []);

  const [showDetails, setShowDetails] = useState(false);
  const [myInterval, setMyInterval] = useState();

  const toggleDetails = () => {
    setShowDetails(!showDetails);
    let interval = setInterval(() => {
      window.scrollBy({
      top: 1000,
      left: 0,
      behavior: 'smooth'
      })
    setMyInterval(interval);
    if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
        clearInterval(interval);
    }
    window.onkeydown = () => {
      clearInterval(interval);
      clear();
    }
    window.onmousemove = () => {
      clearInterval(interval);
      clear();
    }
    window.onmousedown = () => {
      clearInterval(interval);
      clear();
    }

    window.onclick = () => {
      clearInterval(interval);
      clear();
    }
    }, 30);
  };

  const clear = () => {
    clearInterval();
    setShowDetails(!showDetails);
    window.scrollTo(0,0);
  }

  return (
    <div>
      <div className='container mt-5 mb-5'>
        {!showDetails && (
          <div className='accordian-box'>
            <div className='acc-heading mt-5 mx-4'>Crypto Currency</div>
            <div className='acc-text mt-3 mx-4'>Welcome to Alpha Vision Technology, where we believe we have what it takes to revolutionize individuals </div>
            <div className='row flex-column-reverse flex-sm-row'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <button className='view-button mx-4 px-4' onClick={toggleDetails}>View Details<i className="fa-solid fa-chevron-down"></i></button>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 mt-5'>
              <img src={crypto} alt="photo" className="full-width" />
            </div>
          </div>
          </div>
        )}

        {showDetails && (
          <div className='container accordian-box'>
            <div className='row'>
            <div className='acc-heading mt-5 mx-4'>Crypto Currency</div>
            <div className='acc-text mt-3 mx-4'>Welcome to Alpha Vision Technology, where we believe we have what it takes to revolutionize individuals </div>
            <img src={productleave} alt="photo" className="full-width mt-4" /> 
            </div>
            <div className='row mt-3'>
              <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='acc-subheading mt-4 mx-4'>About</div>
                <div className='acc-subtext mt-2 mx-4'>Welcome to Alpha Vision Technology, where we believe we have what it takes to revolutionize individuals and businesses across all industries, ushering in a new era of productivity, efficiency, and competency through the transformative power of technology.</div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='acc-subheading mt-4 mx-4'>Benefits</div>
                <div className='acc-subtext mt-2 mx-4'>Welcome to Alpha Vision Technology, where we believe we have what it takes to revolutionize individuals and businesses across all industries, ushering in a new era of productivity, efficiency, and competency through the transformative power of technology.</div>
                </div>
            </div>
            <div class="horizontal-line mx-4 mt-4"></div>
            <div className='row mt-4'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='acc-subheading mt-5 pt-5 mx-4'>Key Features</div>
                <div className='acc-subtext mt-2 mx-4'>Welcome to Alpha Vision Technology, where we believe we have what it takes to revolutionize individuals and businesses across all industries, ushering in a new era of productivity, efficiency, and competency through the transformative power of technology.Welcome to Alpha Vision Technology, where we believe we have what it takes to revolutionize individuals and businesses across all industries, ushering in a new era of productivity, efficiency, and competency through the transformative power of technology.</div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                <img src={tab} alt="photo" className="full-width" />
                </div>
              </div>
              <div className='row'>
              <div className='acc-subheading mt-4 mx-4'>How It Works</div>
              <div className='acc-subtext mt-2 mx-4'>Welcome to Alpha Vision Technology, where we believe we have what it takes to revolutionize individuals and businesses across all industries, ushering in a new era of productivity, efficiency, and competency through the transformative power of technology.</div>
            <img src={view} alt="photo" className="full-width mt-5 center" /> 
            </div>
            <button className='hide-button mx-4 px-4 mt-5 mb-5' onClick={clear}>hide Details<i className="fa-solid fa-chevron-up"></i></button>
          </div>
        )}
      </div>
    </div>
  );
}
