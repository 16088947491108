
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Location.css';
import malay from '../assets/images/malaysia.png';
import indo from '../assets/images/indo.png';

export default function Location() {
  useEffect(() => {
    // Initialize AOS
    AOS.init();

    // Set active location to Malaysia when component mounts
    setActiveLocation('Malaysia');

    return () => {
      AOS.refresh(); // Remove if you don't want to refresh on unmount
    };
  }, []);

  // State to track active location
  const [activeLocation, setActiveLocation] = useState(null);

  // Function to handle location button click
  const handleLocationClick = (location) => {
    setActiveLocation(location);
  };

  return (
    <div>
      <div className="container mt-5 pt-3">
        <div className="heading mt-5">alpha office location</div>
        <div className="sub-text mt-2">Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam</div>
        <div className="row mt-5 map-box">
          <div className="col-lg-12 col-md-12 col-sm-12 mt-3 pb-3">
            <div className="mapwrapper mt-1">
              {/* Conditional rendering of locations */}
              {activeLocation === 'Malaysia' && (
                <iframe
                  width="100%"
                  height="440"
                  id="gmap_canvas"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.6725611259917!2d106.78722357453059!3d-6.1745734605049005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f72f421b40d7%3A0xa2260e416b98e583!2sWerkspace%20Soho%20Capital!5e0!3m2!1sen!2sin!4v1706495239254!5m2!1sen!2sin"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  title="myFrame"
                ></iframe>
              )}
              {activeLocation === 'Indonesia' && (
                <iframe
                  width="100%"
                  height="440"
                  id="gmap_canvas"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.2157824208166!2d80.11470067454546!3d12.893841916579362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5258b530000001%3A0x62560a8046a5c78f!2sZasta%20Infotek%20private%20ltd.!5e0!3m2!1sen!2sin!4v1708344893369!5m2!1sen!2sin"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  title="myFrame"
                ></iframe>
              )}
            </div>
            <div className='location-heading mt-3'>our locations:</div>
            <div className='location-flex d-flex mt-3 mb-3'>
              {/* Add button for the new location */}
              <div className={`loc-button ${activeLocation === 'Malaysia' ? 'active' : ''}`} onClick={() => handleLocationClick('Malaysia')}>
                <div className='flag-box'>
                  <img src={malay} alt="Malaysia" className="w-100 hover-icon" />
                </div>
                <div className='loc-text-button px-2'>Malaysia</div>
              </div>
              <div className={`loc-button mx-4 ${activeLocation === 'Indonesia' ? 'active' : ''}`} onClick={() => handleLocationClick('Indonesia')}>
                <div className='flag-box'>
                  <img src={indo} alt="Indonesia" className="w-100 hover-icon" />
                </div>
                <div className='loc-text-button px-2'>Indonesia</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
