// import React from "react";
// import './SecondaryBanner.css';

// export default function SecondaryBanner({ heading, subText , dropdownClicked }) {
  
//   return (
//     <div className="bg-secondary" style={{ opacity: dropdownClicked ? 0.1 : 1 }}>
//       <div className="container box-banner">
//         <div className="row align">
//           <div className="heading">{heading}</div>
//           <div className="sub-text mt-3">{subText}</div>
//         </div>
//       </div>
//     </div>
//   );
// }

import React from "react";
import './SecondaryBanner.css';
import Header from "./Header";

export default function SecondaryBanner({ heading, subText}) {
  
  return (
    <div className="bg-secondary">
      <Header />
      <div className="container box-banner mb-5 mt-5 pt-5">
        <div className="row align mt-4">
          <div className="heading">{heading}</div>
          <div className="sub-banner-text mt-3">{subText}</div>
        </div>
      </div>
    </div>
  );
}
