import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../src/Components/Footer";
import m1 from "../src/assets/images/m1.png";
import m2 from "../src/assets/images/m2.png";
import m3 from "../src/assets/images/m3.png";
import m4 from "../src/assets/images/m4.png";
import m5 from "../src/assets/images/m5.png";
import SecondaryBanner from "./Components/SecondaryBanner";
import ContactUs from "./ContactUs";
import "./Service.css";

const Service = () => {
  const headingText = "Services";
  const subTextContent = "Home / Services";

  const [activeTab, setActiveTab] = useState("Marketing");

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sectionId = queryParams.get("section");
    if (sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.search]);

  // Content for each tab
  const tabContent = {
    Marketing: (
      <div>
        <div className="container-fluid mt-5 pt-5 mb-5">
          <div className="row">
            <div className="heading">Marketing</div>
            <div className="sub-text">
              Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
              mi. Aliquam
            </div>
          </div>
        </div>
        <div class="container mt-4">
          <div
            class="row p-3 flex-column-reverse flex-sm-row"
            id="digital-marketing"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">Digital Marketing</div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m1} alt="photo" className="w-100" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row p-3" id="search-engine">
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m2} alt="photo" className="full-width" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">Search Engine Optimization</div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div
            class="row p-3 flex-column-reverse flex-sm-row"
            id="corporate-branding"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">Corporate Branding</div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m3} alt="photo" className="full-width" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row p-3" id="pay-per-click">
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m4} alt="photo" className="full-width" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">Pay-Per-Click (PPC)</div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div
            class="row p-3 flex-column-reverse flex-sm-row"
            id="content-writing"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">Content Writing</div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m5} alt="photo" className="full-width" />
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    Software: (
      <div>
        <div className="container-fluid mt-5 pt-5">
          <div className="row">
            <div className="heading">Software</div>
            <div className="sub-text">
              Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
              mi. Aliquam
            </div>
          </div>
        </div>
        <div class="container mt-4">
          <div
            class="row p-3 flex-column-reverse flex-sm-row"
            id="Customized Software Development"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                Customized Software Development
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m1} alt="photo" className="w-100" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row p-3" id="Mobile App Development">
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m2} alt="photo" className="full-width" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">Mobile App Development</div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div
            class="row p-3 flex-column-reverse flex-sm-row"
            id="Software as a Service (SaaS) Subscription"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                Software as a Service (SaaS) Subscription
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m3} alt="photo" className="full-width" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row p-3" id="Website Development & Administration">
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m4} alt="photo" className="full-width" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                Website Development & Administration
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    Hardware: (
      <div>
        <div className="container-fluid mt-5 pt-5">
          <div className="row">
            <div className="heading">Hardware</div>
            <div className="sub-text">
              Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
              mi. Aliquam
            </div>
          </div>
        </div>
        <div class="container mt-4">
          <div
            class="row p-3 flex-column-reverse flex-sm-row"
            id="Smartphone / Tablet / Desktop PC / Laptop / TV / Projector Repairs & Upgrades"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                Smartphone / Tablet / Desktop PC / Laptop / TV / Projector
                Repairs & Upgrades
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m1} alt="photo" className="w-100" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row p-3" id="Hardware Sourcing & Procurement">
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m2} alt="photo" className="full-width" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                Hardware Sourcing & Procurement
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div
            class="row p-3 flex-column-reverse flex-sm-row"
            id="IT Asset Management"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">IT Asset Management</div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m3} alt="photo" className="full-width" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row p-3" id="Website Development & Administration">
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m4} alt="photo" className="full-width" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                Website Development & Administration
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    Networking: (
      <div>
        <div className="container-fluid mt-5 pt-5">
          <div className="row">
            <div className="heading">Networking</div>
            <div className="sub-text">
              Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
              mi. Aliquam
            </div>
          </div>
        </div>
        <div class="container mt-4">
          <div
            class="row p-3 flex-column-reverse flex-sm-row"
            id="Network Architecture & Design"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                Network Architecture & Design
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m1} alt="photo" className="w-100" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div
            class="row p-3"
            id="Network Installation, Configuration, & Upgrades"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m2} alt="photo" className="full-width" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                Network Installation, Configuration, & Upgrades
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div
            class="row p-3 flex-column-reverse flex-sm-row"
            id="Network Audit & Assessment"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">Network Audit & Assessment</div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m3} alt="photo" className="full-width" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row p-3" id="Network Hardware & Procurement">
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m4} alt="photo" className="full-width" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                Network Hardware & Procurement
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    DataRecovery: (
      <div>
        <div className="container-fluid mt-5 pt-5">
          <div className="row">
            <div className="heading">DataRecovery</div>
            <div className="sub-text">
              Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
              mi. Aliquam
            </div>
          </div>
        </div>
        <div class="container mt-4">
          <div
            class="row p-3 flex-column-reverse flex-sm-row"
            id="Hard Drive Data Recovery"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">Hard Drive Data Recovery</div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m1} alt="photo" className="w-100" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row p-3" id="Email Database Recovery">
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m2} alt="photo" className="full-width" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">Email & Database Recovery</div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div
            class="row p-3 flex-column-reverse flex-sm-row"
            id="Server RAID Data Recovery"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                Server & RAID Data Recovery
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m3} alt="photo" className="full-width" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row p-3" id="USB Drives Memory Card Recovery">
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m4} alt="photo" className="full-width" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                USB Drives & Memory Card Recovery
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div
            class="row p-3 flex-column-reverse flex-sm-row"
            id="Mobile Device Data Recovery"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                Mobile Device Data Recovery
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m5} alt="photo" className="full-width" />
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    ArtificialIntelligence: (
      <div>
        <div className="container-fluid mt-5 pt-5">
          <div className="row">
            <div className="heading">AI</div>
            <div className="sub-text">
              Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
              mi. Aliquam
            </div>
          </div>
        </div>
      </div>
    ),
    Security: (
      <div>
        <div className="container-fluid mt-5 pt-5">
          <div className="row">
            <div className="heading">Security</div>
            <div className="sub-text">
              Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
              mi. Aliquam
            </div>
          </div>
        </div>
        <div class="container mt-4">
          <div
            class="row p-3 flex-column-reverse flex-sm-row"
            id="IT Audit, Compliance, & Assurance"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                IT Audit, Compliance, & Assurance
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m1} alt="photo" className="w-100" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row p-3" id="Data Loss Prevention & Disaster Recovery">
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m2} alt="photo" className="full-width" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                Data Loss Prevention & Disaster Recovery
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div
            class="row p-3 flex-column-reverse flex-sm-row"
            id="Security Policy Development & Assessment"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                Security Policy Development & Assessment
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m3} alt="photo" className="full-width" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div
            class="row p-3"
            id="CCTV Surveillance & Remote Monitoring Solution"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m4} alt="photo" className="full-width" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                CCTV Surveillance & Remote Monitoring Solution
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    ITconsulting: (
      <div>
        <div className="container-fluid mt-5 pt-5">
          <div className="row">
            <div className="heading">ITconsulting</div>
            <div className="sub-text">
              Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
              mi. Aliquam
            </div>
          </div>
        </div>
        <div class="container mt-4">
          <div
            class="row p-3 flex-column-reverse flex-sm-row"
            id="Cloud Technology"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">Cloud Technology</div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m1} alt="photo" className="w-100" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row p-3" id="Software Licensing & Procurement">
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m2} alt="photo" className="full-width" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                Software Licensing & Procurement
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div
            class="row p-3 flex-column-reverse flex-sm-row"
            id="Business Process & Outsourcing"
          >
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                Business Process & Outsourcing
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m3} alt="photo" className="full-width" />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row p-3" id="Business Transformation & Automation">
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="image-set">
                <img src={m4} alt="photo" className="full-width" />
              </div>
            </div>
            <div
              class="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="sub-heading mt-3">
                Business Transformation & Automation
              </div>
              <div className="sub-para mt-3">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
              </div>
              <div>
                <ContactUs className="button-9" property1="default" />
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
  };

  // Function to handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <SecondaryBanner heading={headingText} subText={subTextContent} />
      <div className="container mt-4">
        <div className="row">
          <div className="heading">We offer various services</div>
          <div className="sub-text">
            Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
            mi. Aliquam
          </div>
        </div>
      </div>
      <div className="container mt-3">
        <div className="row tab-hide">
          {/* Map through the tabs and render buttons */}
          {Object.keys(tabContent).map((tab) => (
            <div
              key={tab}
              className="col-lg-3 col-md-6 col-sm-12 mt-3"
              onClick={() => handleTabClick(tab)}
            >
              <button
                className={`tab-button ${
                  activeTab === tab ? "active-tab" : ""
                }`}
              >
                {tab}
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="container">
        {/* Render content based on active tab */}
        <div className="row">
          <div className="col-lg-12">
            <div>{tabContent[activeTab]}</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Service;
