import React, { useRef, useState } from "react";
import Header from "./Components/Header";
import SecondaryBanner from "./Components/SecondaryBanner";
import Connect from "./Components/Connect";
import Form from "./Components/Form";
import Location from "./Components/Location";
import Footer from "./Components/Footer";
import "./Contact.css";

const Contact = () => {
  const headingText = "Contact Us";
  const subTextContent = "Home / Contact Us";

  return (
    <div>
      <SecondaryBanner heading={headingText} subText={subTextContent} />
      <div className="custom">
        <Connect />
        <Form />
        <Location />
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
