import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Connect.css';
import mobile from '../assets/images/mobile.png';
import mail from '../assets//images/mail.png';
import social from '../assets/images/social.png';
import location from '../assets/images/location.png';
import link from '../assets/images/cl.svg';
import face from '../assets/images/cf.svg';
import whatsi from '../assets/images/cw.svg';
import insta from '../assets/images/ci.svg';

export default function Connect() {
  useEffect(() => {
    // Initialize AOS
    AOS.init();

    return () => {
      AOS.refresh(); // Remove if you don't want to refresh on unmount
    };
  }, []);
  return (
    <div>
     <div className="container mt-5 pt-5">
      <div className="row mt-5">
      <div className="heading">Connect with us now.</div>
        <div className="sub-text">Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam</div>
      </div>
     </div>
     <div className="container mt-5">
      <div className="row">
       <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="connect-box" data-aos="fade-up" data-aos-duration="1500">
        <div className="icon-box mt-5">
        <img src={mobile} alt="Logo" className="w-100" />
        </div>
        <div className="text mt-4">(+62) 812-9250-9595</div>
        <div className="text mt-3">(+62) 823-1024-2185</div>
        <div className="text mt-3">(+60) 178-6572-12</div>
        </div>
       </div>
       <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="connect-box space-m" data-aos="fade-down" data-aos-duration="1500">
        <div className="icon-box mt-5">
        <img src={mail} alt="Logo" className="w-100" />
        </div>
        <div className="text mt-5">info@alphavtechnology.com</div>
        <div className="text mt-3">alikhan@alphavtechnology.com</div>
        </div>
       </div>
       <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="connect-box space-s" data-aos="fade-up" data-aos-duration="1500">
        <div className="icon-box mt-5">
        <img src={social} alt="Logo" className="w-100" />
        </div>
     <div className='center'>
     <div className="flex mt-5">
        <div className="social-box">
        <img src={link} alt="Logo" className="w-100 hover-icon blur" />
        </div>
        <div className="social-box">
        <img src={face} alt="Logo" className="w-100 hover-icon blur" />
        </div>
        <div className="social-box">
        <img src={insta} alt="Logo" className="w-100 hover-icon blur" />
        </div>
        <div className="social-box">
        <img src={whatsi} alt="Logo" className="w-100 hover-icon blur" />
        </div>
        </div>
     </div>
        </div>
       </div>
       <div className="col-lg-3 col-md-6 col-sm-12">
        <div className="connect-box space-s" data-aos="fade-down" data-aos-duration="1500">
        <div className="icon-box mt-5">
        <img src={location} alt="Logo" className="w-100" />
        </div>
        <div className="text mt-5">25-08, Soho Capital, Podomoro City,
Grogol Petamburan, Jakarta Barat,
DKI Jakarta 11470, Indonesia.</div>
        </div>
       </div>
      </div>
     </div>
    </div>
  );
}
