import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Footer.css';
import logo from '../assets/images/logo_new.png';
import link from '../assets/images/link.svg';
import face from '../assets/images/face.svg';
import whatsi from '../assets/images/whatsi.svg';
import insta from '../assets/images/insta.svg';
import footer from '../assets/images/footer.png';
import sublogo from '../assets/images/sublogo.png';

import { Link } from 'react-router-dom';


export default function AboutDescribe() {
  useEffect(() => {
    // Initialize AOS
    AOS.init();

    return () => {
      AOS.refresh();
    };
  }, []);
  return (
    <div>
      <div className="container mt-5 pt-3">
        {/* <div className='circle-in'>
            <div class="half-circle">
            <div className='box-footer'>
            <div className="logo-box-footer mt-5 pt-5">
              <img src={logo} alt="Logo" className="w-100" />
            </div>
            <div className='footer-text pt-5 mt-5'>Lorem ipsum dolor sit amet consectetur adipiscing elitLorem ipsum dolor sit amet c,</div>
            <div className='row mt-5'>
              <div className='col-lg-12'>
               <div className='row'>
                <div className='col-lg-1'></div>
                <div className='col-lg-2'>
                  <button className='link-button'>Home</button>
                </div>
                <div className='col-lg-2'>
                <button className='link-button'>About us</button>
                </div>
                <div className='col-lg-2'>
                <button className='link-button'>Services</button>
                </div>
                <div className='col-lg-2'>
                <button className='link-button'>Our Products</button>
                </div>
                <div className='col-lg-2'>
                <button className='link-button'>Blog</button>
                </div>
                <div className='col-lg-1'></div>
               </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-9 col-md-12 col-sm-12'>
              <div className='footer-subtext pt-5 mt-5'>© AlphaVisionTechnology - All Rights Reserved</div>
              </div>
              <div className='col-lg-3 col-md-12 col-sm-12'>
              <div className="flex mt-5 pt-5">
        <div className="social-box">
        <img src={linked} alt="Logo" className="w-100" />
        </div>
        <div className="social-box">
        <img src={facebook} alt="Logo" className="w-100" />
        </div>
        <div className="social-box">
        <img src={instagram} alt="Logo" className="w-100" />
        </div>
        <div className="social-box">
        <img src={whatsapp} alt="Logo" className="w-100" />
        </div>
        </div>
              </div>
            </div>
            </div>
            </div>
            </div> */}
        <div className='row mt-5 pt-5'>
          <div className='col-lg-4 col-md-12 col-sm-12'>
            <div className='footer-logo-box'>
              <img src={logo} alt="Logo" className="w-100" />
            </div>
            <div className='footer-text mt-2 px-3'>Lorem ipsum dolor sit amet consectetur adipiscing elitLorem ipsum dolor sit amet c,</div>
            <div className="flex-social mt-4 mb-3">
              <div className="social-box">
                <img src={link} alt="Logo" className="w-100 hover-icon" />
              </div>
              <div className="social-box">
                <img src={face} alt="Logo" className="w-100 hover-icon" />
              </div>
              <div className="social-box">
                <img src={insta} alt="Logo" className="w-100 hover-icon" />
              </div>
              <div className="social-box">
                <img src={whatsi} alt="Logo" className="w-100 hover-icon" />
              </div>
            </div>
          </div>
          <div className='col-lg-2 col-md-12 col-sm-12 space-s'>
            <Link to="/"><div className='footer-heading'>Home</div></Link>
          </div>
          <div className='col-lg-2 col-md-12 col-sm-12 space-s'>
            <Link to="/About"><div className='footer-heading'>About Us</div></Link>
            <div className='mt-3'>
              <Link to="/"><div className='footer-sub'>Terms</div></Link>
            </div>
          </div>
          <div className='col-lg-2 col-md-12 col-sm-12 space-s'>
            <Link to="/Product"><div className='footer-heading'>Our Products</div></Link>
            <div className='mt-3'>
              <Link to="/"><div className='footer-sub'>Crypto</div></Link>
              <Link to="/"><div className='footer-sub mt-2'>E-commerce</div></Link>
              <Link to="/"> <div className='footer-sub mt-2'>Management</div></Link>
            </div>
          </div>
          <div className='col-lg-2 col-md-12 col-sm-12 space-s'>
            <Link to=""><div className='footer-heading'>Services</div></Link>
            <div className='mt-3'>
              <Link to=""><div className='footer-sub'>Security</div></Link>
              <Link to="/"><div className='footer-sub mt-2'>Hardware</div></Link>
              <Link to="/"><div className='footer-sub mt-2'>Software</div></Link>
              <Link to="/"><div className='footer-sub mt-2'>Networking</div></Link>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='container-fluid'>
        <div className='footer'>
          <div className='back mt-5'>
          <div className='circle-in mt-5'>
            <div className='half-circle'>

            </div>
          </div>
          </div>
            </div>
        </div> */}
      {/* <div className='container-fluid'>
        <div className='back'>
          <div className='circle-in mt-5'>
            <div className='half-circle'>
              <div className='footer-logo'>
                <img src={sublogo} alt="person" className="w-100" />
              </div>
              <div className='footer-text-bottom mt-5 pt-5'>© AlphaVisionTechnology - All Rights Reserved</div>
            </div>
          </div>
        </div>
      </div> */}


      <div className='half-circle'>
        <div className='footer-logo'>
          <img src={sublogo} alt="person" className="w-100" />
        </div>
        <div className='footer-bottom-txt mt-5'>© AlphaVisionTechnology - All Rights Reserved</div>
      </div>





    </div>
  );
}
