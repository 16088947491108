
import React, { useState } from "react";
import Header from "./Components/Header";
import News from './Components/News';
import Footer from './Components/Footer';
import Search from "./Components/Search";
import './Blog.css';
import SecondaryBanner from "./Components/SecondaryBanner";
import sampleblog from '../src/assets/images/blogsample.png';
import NewsLetter from "./Components/NewsLetter";

const Blog = () => {

  const [currentPage, setCurrentPage] = useState(1);

  const paragraphText = "Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque";

  const headingText = "Blog";
  const subTextContent = "Home / Blog";

    // Function to handle page click
    const handlePageClick = (page) => {
      setCurrentPage(page);
      // You can perform any action when a page is clicked, such as fetching data for the clicked page
    };

  return (
    <div>
       <SecondaryBanner heading={headingText} subText={subTextContent}/>
       <News />
       <Search />
       <div>
       <div className="container mt-5 custom">
       <div className='row mt-3'>
                <div className='col-lg-4 col-md-12 col-sm-12' data-aos="flip-right" data-aos-duration="1000">
                   <div className='blog-box'>
                    <div className='blog-image'>
                    <img src={sampleblog} alt="photo" className="full-width" />
                    </div>
                    <div className='blog-heading mt-3'>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque</div>
                    <div className="blog-text mt-3 limited-lines">
      {paragraphText}
    </div>
                    <div className='load mt-3'>See More</div>
                   </div>
                </div>
                <div className='col-lg-4 col-md-12 col-sm-12 space-s' data-aos="flip-right" data-aos-duration="1000">
                <div className='blog-box'>
                    <div className='blog-image'>
                    <img src={sampleblog} alt="photo" className="full-width" />
                    </div>
                    <div className='blog-heading mt-3'>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque</div>
                    <div className="blog-text mt-3 limited-lines">
      {paragraphText}
    </div>
                    <div className='load mt-3'>See More</div>
                   </div>
                </div>
                <div className='col-lg-4 col-md-12 col-sm-12 space-s' data-aos="flip-right" data-aos-duration="1000">
                <div className='blog-box'>
                    <div className='blog-image'>
                    <img src={sampleblog} alt="photo" className="full-width" />
                    </div>
                    <div className='blog-heading mt-3'>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque</div>
                    <div className="blog-text mt-3 limited-lines">
      {paragraphText}
    </div>
                    <div className='load mt-3'>See More</div>
                   </div>
                </div>
            </div>
       </div>
       <div className="container mt-3 custom">
       <div className='row mt-2'>
                <div className='col-lg-4 col-md-12 col-sm-12' data-aos="flip-right" data-aos-duration="1000">
                   <div className='blog-box'>
                    <div className='blog-image'>
                    <img src={sampleblog} alt="photo" className="full-width" />
                    </div>
                    <div className='blog-heading mt-3'>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque</div>
                    <div className="blog-text mt-3 limited-lines">
      {paragraphText}
    </div>
                    <div className='load mt-3'>See More</div>
                   </div>
                </div>
                <div className='col-lg-4 col-md-12 col-sm-12 space-s' data-aos="flip-right" data-aos-duration="1000">
                <div className='blog-box'>
                    <div className='blog-image'>
                    <img src={sampleblog} alt="photo" className="full-width" />
                    </div>
                    <div className='blog-heading mt-3'>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque</div>
                    <div className="blog-text mt-3 limited-lines">
      {paragraphText}
    </div>
                    <div className='load mt-3'>See More</div>
                   </div>
                </div>
                <div className='col-lg-4 col-md-12 col-sm-12 space-s' data-aos="flip-right" data-aos-duration="1000">
                <div className='blog-box'>
                    <div className='blog-image'>
                    <img src={sampleblog} alt="photo" className="full-width" />
                    </div>
                    <div className='blog-heading mt-3'>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque</div>
                    <div className="blog-text mt-3 limited-lines">
      {paragraphText}
    </div>
                    <div className='load mt-3'>See More</div>
                   </div>
                </div>
            </div>
       </div>
       </div>
           {/* Pagination dots */}
           <div className="pagination-dots">
         {[1, 2, 3, 4, 5].map(page => (
           <span key={page} className={`dots ${currentPage === page ? 'active' : ''}`} onClick={() => handlePageClick(page)}></span>
         ))}
       </div>
       <NewsLetter />
       <Footer />
    </div>
  );
};

export default Blog;
