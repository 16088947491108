import React, { useEffect } from 'react';
import './App.css';
import Home from './Home';
import Blog from './Blog';
import About from './About';
import Contact from './Contact';
import Product from './Product';
import Service from './Service';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './Components/scrollToTop';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route path='/' action={window.scrollTo(0,0)} element={<Home />} />
        <Route path='/Blog' element={<Blog />} />
        <Route path='/About' element={<About />} />
        <Route path='/Service' element={<Service />} />
        <Route path='/Product' element={<Product />} />
        <Route path='/Contact' element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
