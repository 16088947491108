import React, { useState, useEffect } from "react";
import Header from '../src/Components/Header';
import SecondaryBanner from '../src/Components/SecondaryBanner';
import AboutDescribe from '../src/Components/AboutDescribe';
import Team from '../src/Components/Team';
import sample1 from '../src/assets/images/vector-38.png';
import sample2 from '../src/assets/images/subtract-41.png';
import sample3 from '../src/assets/images/subtract-40.png';
import Footer from '../src/Components//Footer';

const AboutPage = () => {


  const headingText = "About Us";
  const subTextContent = "Home / About Us";

  const aboutTitle1 = 'About Us';
  const aboutDescription1 = [
    'Welcome to Alpha Vision Technology, where we believe we have what it takes to revolutionize individuals and businesses across all industries, ushering in a new era of productivity, efficiency, and competency through the transformative power of technology.',
    'Alpha Vision Technology represents a collaboration between two well-established and reputable technology providers in Malaysia. One specializes in hardware-related services, while the other brings expertise from the software industry. Through the synergies of these two companies, we provide an extensive range of solutions & services to our customer base in Indonesia, further expanding our clientele throughout Southeast Asia.',
  ];


  const aboutTitle2 = 'Our Vision';
  const aboutDescription2 = [
    'To revolutionize people and businesses across all industries to work productively, efficiently, and intelligently, with the power of technology.',
  ];


  const aboutTitle3 = 'Our Mission';
  const aboutDescription3 = [
    'We aim to become the leading IT solution provider for SMEs across Southeast Asia region with unparalleled service & competence. We aspire to be a trusted business partner, having long-term relationship with our clients, a rewarding workplace for our employees, and a positive force for the community.',
  ];


  return (
    <div>
       <SecondaryBanner heading={headingText} subText={subTextContent}/>
    <div className='container mt-5 pt-5'>
    <AboutDescribe
        title={aboutTitle1}
        description={aboutDescription1}
        imageSrc={sample1}
        mainBoxHeight="470px" //default height
        mbvalue="0px"
        customClass="part-custom"
      />
    </div>
      <div className='container mt-4'>  
        <div className='row'>
          <div className='col-lg-6 col-md-12 col-sm-12'>
          <AboutDescribe
        title={aboutTitle3}
        description={aboutDescription2}
        imageSrc={sample2}
        mainBoxHeight="415px" // Default height
        smallScreenHeight="250px" // Height for smaller screens
        mbvalue="47px"
      />
          </div>
          <div className='col-lg-6 col-md-12 col-sm-12'>
          <AboutDescribe
        title={aboutTitle2}
        description={aboutDescription3}
        imageSrc={sample3}
        mainBoxHeight="415px" // Default height
        smallScreenHeight="250px" // Height for smaller screens
        mbvalue="0px"
      />
          </div>
        </div>
      </div>
      <Team />
      <Footer />
    </div>
  );
}

export default AboutPage;
