import React, { useReducer, useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Form.css';

import subtract from "../assets/images/subtract.svg";
  


export default function AboutDescribe() {
  const reducer = (state, action) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "mouse_enter":
        return {
          ...state,
          property1: "variant-2",
        };

      case "mouse_leave":
        return {
          ...state,
          property1: "default",
        };
    }

    return state;
  };

  const [state, dispatch] = useReducer(reducer, {
    property1: "default",
  });

  useEffect(() => {
    // Initialize AOS
    AOS.init();

    return () => {
      AOS.refresh(); // Remove if you don't want to refresh on unmount
    };
  }, []);
  return (
    <div>
      <div
        className="container form-box mt-5 pt-5"
        data-aos="zoom-in"
        data-aos-duration="1500"
      >
        <div className="heading">Get in touch with us</div>
        <div className="sub-text">
          Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi.
          Aliquam
        </div>
        <div className="row mt-4">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <input
              type="text"
              className="form-input"
              placeholder="Enter your name"
            ></input>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 space-s">
            <input
              type="text"
              className="form-input"
              placeholder="Phone Number"
            ></input>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <input
              type="text"
              className="form-input"
              placeholder="E-mail Address"
            ></input>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 space-s">
            <select className="form-input" required>
              <option value="" disabled selected>
                How did you hear about us?
              </option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </select>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-12">
            <textarea
              className="text-input"
              placeholder="Type your message here"
              rows="5"
              cols="50"
            ></textarea>
          </div>
        </div>
        <div className="center">
          <button
            className={`button-6 mt-3`}
            onMouseLeave={() => {
              dispatch("mouse_leave");
            }}
            onMouseEnter={() => {
              dispatch("mouse_enter");
            }}
          >
            <div className={`overlap-group-5 property-1-7-${state.property1}`}>
              <div className="text-wrapper-6">Submit</div>
              {state.property1 === "default" && (
                <img className="subtract-5" alt="Subtract" src={subtract} />
              )}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
