// In your Header.js file
import React, { useReducer, useState } from "react";
import './Header.css';
import Logo from '../assets/images/logo_new.png';
import { Link, NavLink } from "react-router-dom";
import dm from '../assets/images/di.png';
import navline from '../assets/images/navline.png';
import cb from '../assets/images/cbi.png';
import ppc from '../assets/images/ppci.png';
import cw from '../assets/images/cwi.png';
import se from '../assets/images/seoi.png';
import subtract from '../assets/images/subtract.svg'

export default function Header({ setDropdownClicked }) {

  
  // State to manage active tab
  const [activeTab, setActiveTab] = useState("marketing");

  
const reducer = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "variant-2",
      };

    case "mouse_leave":
      return {
        ...state,
        property1: "default",
      };
  }

  return state;
}

  const [state, dispatch] = useReducer(reducer, {
    property1: "default",
  });

  // Function to handle tab change
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  }

  // Function to prevent event propagation
  const stopPropagation = (event) => {
    event.stopPropagation();
  }

  // Function to handle dropdown click
  const handleDropdownClick = () => {
    if (setDropdownClicked) {
      setDropdownClicked(true);
    }
  }




  return ( 
    <div>
      <nav className="navbar navbar-expand-lg p-3 mt-3">
        <div className="container">
          <div className="logo-box">
            <NavLink className="navbar-brand logo-box" to="/">
              <img src={Logo} alt="Logo" className="w-100" />
            </NavLink>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  exact
                  to="/"
                >
                  Home
                </NavLink>
                <img src={navline} alt="Logo" className="active-indicator" />
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/About"
                >
                  About Us
                </NavLink>
                <img src={navline} alt="Logo" className="active-indicator" />
              </li>
              <li className="nav-item dropdown item-space">
                <a className="nav-link navigation dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleDropdownClick}>
                  Services
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown" onClick={stopPropagation}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-4 bg-blur">
                        <div className="mt-4 mb-4">
                          {/* Change active tab on button click */}
                          <div className={`select-button ${activeTab === "hardware" ? "active" : ""}`} onClick={() => handleTabChange("hardware")}>Hardware</div>
                          <div className={`select-button ${activeTab === "software" ? "active" : ""}`} onClick={() => handleTabChange("software")}>Software</div>
                          <div className={`select-button ${activeTab === "marketing" ? "active" : ""}`} onClick={() => handleTabChange("marketing")}>Marketing</div>
                          <div className={`select-button ${activeTab === "networking" ? "active" : ""}`} onClick={() => handleTabChange("networking")}>Networking</div>
                          <div className={`select-button ${activeTab === "data-recovery" ? "active" : ""}`} onClick={() => handleTabChange("data-recovery")}>Data Recovery</div>
                          <div className={`select-button ${activeTab === "AI" ? "active" : ""}`} onClick={() => handleTabChange("AI")}>Artificial Intelligence</div>
                          <div className={`select-button ${activeTab === "security" ? "active" : ""}`} onClick={() => handleTabChange("security")}>Security</div>
                          <div className={`select-button ${activeTab === "IT consulting" ? "active" : ""}`} onClick={() => handleTabChange("IT consulting")}>IT Consulting</div>
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                        {/* Render content based on active tab */}
                        <div className="required-content">
                          {activeTab === "hardware" && 
                          <div className="row mt-2">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                            <Link to="/Service?section=Smartphone / Tablet / Desktop PC / Laptop / TV / Projector Repairs & Upgrades" style={{ textDecoration: 'none'}}>
                            <div className="header-content">
                                <div className="row">
                                  <div className="col-lg-2 col-md-12 com-sm-12">
                                  <div className="header-box">
                                <div className="headericon">
                                <img src={dm} alt="Logo" className="w-100" />
                                </div>
                                    </div>
                                  </div>
                                 <div className="col-lg-10 col-md-12 col-sm-12">
                                 <div className="content mt-1">
                                    <div className="link-heading">Smartphone / Tablet / Desktop PC / Laptop / TV / Projector Repairs & Upgrades</div>
                                    <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                    </div>
                                 </div>
                                  </div>
                              </div>
                            </Link>
                            <Link to="/Service?section=Hardware Sourcing & Procurement" style={{ textDecoration: 'none'}}>
                              <div className="header-content">
                                <div className="row">
                                <div className="col-lg-2 col-md-12 com-sm-12">
                                  <div className="header-box">
                                  <div className="headericon">
                                <img src={cb} alt="Logo" className="w-100" />
                                </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-10 col-md-12 col-sm-12">
                                  <div className="content mt-1">
                                    <div className="link-heading">Hardware Sourcing & Procurement</div>
                                    <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                    </div>
                                 </div>
                                  </div>
                              </div>
                              </Link>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                            <Link to="/Service?section=IT Asset Management" style={{ textDecoration: 'none'}}>
                              <div className="header-content">
                                <div className="row">
                                <div className="col-lg-2 col-md-12 com-sm-12">
                                  <div className="header-box">
                                  <div className="headericon">
                                <img src={cw} alt="Logo" className="w-100" />
                                </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-10 col-md-12 col-sm-12">
                                  <div className="content mt-1">
                                    <div className="link-heading">IT Asset Management</div>
                                    <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                    </div>
                                 </div>
                                  </div>
                              </div>
                              </Link>
                              <Link to="/Service?section=Server Installation & Configuration" style={{ textDecoration: 'none'}}>
                              <div className="header-content mt-5 pt-5">
                                <div className="row">
                                <div className="col-lg-2 col-md-12 com-sm-12">
                                  <div className="header-box">
                                  <div className="headericon">
                                <img src={se} alt="Logo" className="w-100" />
                                </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-10 col-md-12 col-sm-12">
                                  <div className="content mt-1">
                                    <div className="link-heading">Server Installation & Configuration</div>
                                    <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                    </div>
                                 </div>
                                  </div>
                              </div>
                              </Link>
                            </div>
                          </div>
                          }
                          {activeTab === "software" && 
                              <div className="row mt-2">
                              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                <Link to="/Service?section=Customized Software Development" style={{ textDecoration: 'none'}}>
                                <div className="header-content">
                                    <div className="row">
                                      <div className="col-lg-2 col-md-12 com-sm-12">
                                      <div className="header-box">
                                    <div className="headericon">
                                    <img src={dm} alt="Logo" className="w-100" />
                                    </div>
                                        </div>
                                      </div>
                                     <div className="col-lg-10 col-md-12 col-sm-12">
                                     <div className="content mt-1">
                                        <div className="link-heading">Customized Software Development</div>
                                        <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                        </div>
                                     </div>
                                      </div>
                                  </div>
                                </Link>
                                <Link to="/Service?section=Mobile App Development" style={{ textDecoration: 'none'}}>
                                  <div className="header-content">
                                    <div className="row">
                                    <div className="col-lg-2 col-md-12 com-sm-12">
                                      <div className="header-box">
                                      <div className="headericon">
                                    <img src={cb} alt="Logo" className="w-100" />
                                    </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-10 col-md-12 col-sm-12">
                                      <div className="content mt-1">
                                        <div className="link-heading">Mobile App Development</div>
                                        <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                        </div>
                                     </div>
                                      </div>
                                  </div>
                                  </Link>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                <Link to="/Service?section=Software as a Service (SaaS) Subscription" style={{ textDecoration: 'none'}}>
                                  <div className="header-content">
                                    <div className="row">
                                    <div className="col-lg-2 col-md-12 com-sm-12">
                                      <div className="header-box">
                                      <div className="headericon">
                                    <img src={cw} alt="Logo" className="w-100" />
                                    </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-10 col-md-12 col-sm-12">
                                      <div className="content mt-1">
                                        <div className="link-heading">Software as a Service (SaaS) Subscription</div>
                                        <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                        </div>
                                     </div>
                                      </div>
                                  </div>
                                  </Link>
                                  <Link to="/Service?section=Website Development & Administration" style={{ textDecoration: 'none'}}>
                                  <div className="header-content">
                                    <div className="row">
                                    <div className="col-lg-2 col-md-12 com-sm-12">
                                      <div className="header-box">
                                      <div className="headericon">
                                    <img src={se} alt="Logo" className="w-100" />
                                    </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-10 col-md-12 col-sm-12">
                                      <div className="content mt-1">
                                        <div className="link-heading">Website Development & Administration</div>
                                        <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                        </div>
                                     </div>
                                      </div>
                                  </div>
                                  </Link>
                                </div>
                              </div>
                          }
                          {activeTab === "marketing" &&
                           <div className="row mt-2">
                           <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                             <Link to="/Service?section=digital-marketing" style={{ textDecoration: 'none'}}>
                             <div className="header-content">
                                 <div className="row">
                                   <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                 <div className="headericon">
                                 <img src={dm} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                  <div className="col-lg-10 col-md-12 col-sm-12">
                                  <div className="content mt-1">
                                     <div className="link-heading">Digital Marketing</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                             </Link>
                             <Link to="/Service?section=corporate-branding" style={{ textDecoration: 'none'}}>
                               <div className="header-content">
                                 <div className="row">
                                 <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                   <div className="headericon">
                                 <img src={cb} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                   <div className="col-lg-10 col-md-12 col-sm-12">
                                   <div className="content mt-1">
                                     <div className="link-heading">Corporate Branding</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                               </Link>
                               <Link to="/Service?section=pay-per-click" style={{ textDecoration: 'none'}}>
                               <div className="header-content">
                                 <div className="row">
                                 <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                   <div className="headericon">
                                 <img src={ppc} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                   <div className="col-lg-10 col-md-12 col-sm-12">
                                   <div className="content mt-1">
                                     <div className="link-heading">Pay-per-click(ppc)</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                               </Link>
                             </div>
                             <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                             <Link to="/Service?section=content-writing" style={{ textDecoration: 'none'}}>
                               <div className="header-content">
                                 <div className="row">
                                 <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                   <div className="headericon">
                                 <img src={cw} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                   <div className="col-lg-10 col-md-12 col-sm-12">
                                   <div className="content mt-1">
                                     <div className="link-heading">Content Writing</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                               </Link>
                               <Link to="/Service?section=search-engine" style={{ textDecoration: 'none'}}>
                               <div className="header-content">
                                 <div className="row">
                                 <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                   <div className="headericon">
                                 <img src={se} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                   <div className="col-lg-10 col-md-12 col-sm-12">
                                   <div className="content mt-1">
                                     <div className="link-heading">Search Engine Optimization</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                               </Link>
                             </div>
                           </div>
                          }
                          {activeTab === "networking" &&
                           <div className="row mt-2">
                           <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                             <Link to="/Service?section=Network Architecture & Design" style={{ textDecoration: 'none'}}>
                             <div className="header-content">
                                 <div className="row">
                                   <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                 <div className="headericon">
                                 <img src={dm} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                  <div className="col-lg-10 col-md-12 col-sm-12">
                                  <div className="content mt-1">
                                     <div className="link-heading">Network Architecture & Design</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                             </Link>
                             <Link to="/Service?section=Network Installation, Configuration, & Upgrades" style={{ textDecoration: 'none'}}>
                               <div className="header-content">
                                 <div className="row">
                                 <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                   <div className="headericon">
                                 <img src={cb} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                   <div className="col-lg-10 col-md-12 col-sm-12">
                                   <div className="content mt-1">
                                     <div className="link-heading">Network Installation, Configuration, & Upgrades</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                               </Link>
                             </div>
                             <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                             <Link to="/Service?section=Network Hardware & Procurement" style={{ textDecoration: 'none'}}>
                               <div className="header-content">
                                 <div className="row">
                                 <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                   <div className="headericon">
                                 <img src={cw} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                   <div className="col-lg-10 col-md-12 col-sm-12">
                                   <div className="content mt-1">
                                     <div className="link-heading">Network Hardware & Procurement</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                               </Link>
                               <Link to="/Service?section=Network Audit & Assessment" style={{ textDecoration: 'none'}}>
                               <div className="header-content">
                                 <div className="row">
                                 <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                   <div className="headericon">
                                 <img src={se} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                   <div className="col-lg-10 col-md-12 col-sm-12">
                                   <div className="content mt-1">
                                     <div className="link-heading">Network Audit & Assessment</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                               </Link>
                             </div>
                           </div>
                           }
                          {activeTab === "data-recovery" &&
                           <div className="row mt-2">
                           <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                             <Link to="/Service?section=Hard Drive Data Recovery" style={{ textDecoration: 'none'}}>
                             <div className="header-content">
                                 <div className="row">
                                   <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                 <div className="headericon">
                                 <img src={dm} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                  <div className="col-lg-10 col-md-12 col-sm-12">
                                  <div className="content mt-1">
                                     <div className="link-heading">Hard Drive Data Recovery</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                             </Link>
                             <Link to="/Service?section=Email Database Recovery" style={{ textDecoration: 'none'}}>
                               <div className="header-content">
                                 <div className="row">
                                 <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                   <div className="headericon">
                                 <img src={cb} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                   <div className="col-lg-10 col-md-12 col-sm-12">
                                   <div className="content mt-1">
                                     <div className="link-heading">Email & Database Recovery</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                               </Link>
                               <Link to="/Service?section=Server RAID Data Recovery" style={{ textDecoration: 'none'}}>
                               <div className="header-content">
                                 <div className="row">
                                 <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                   <div className="headericon">
                                 <img src={ppc} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                   <div className="col-lg-10 col-md-12 col-sm-12">
                                   <div className="content mt-1">
                                     <div className="link-heading">Server & RAID Data Recovery</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                               </Link>
                             </div>
                             <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                             <Link to="/Service?section=USB Drives Memory Card Recovery" style={{ textDecoration: 'none'}}>
                               <div className="header-content">
                                 <div className="row">
                                 <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                   <div className="headericon">
                                 <img src={cw} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                   <div className="col-lg-10 col-md-12 col-sm-12">
                                   <div className="content mt-1">
                                     <div className="link-heading">USB Drives & Memory Card Recovery</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                               </Link>
                               <Link to="/Service?section=Mobile Device Data Recovery" style={{ textDecoration: 'none'}}>
                               <div className="header-content">
                                 <div className="row">
                                 <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                   <div className="headericon">
                                 <img src={se} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                   <div className="col-lg-10 col-md-12 col-sm-12">
                                   <div className="content mt-1">
                                     <div className="link-heading">Mobile Device Data Recovery</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                               </Link>
                             </div>
                           </div>
                          }
                          {activeTab === "AI" && <div>Software Content</div>}
                          {activeTab === "security" && 
                           <div className="row mt-2">
                           <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                             <Link to="/Service?section=IT Audit, Compliance, & Assurance" style={{ textDecoration: 'none'}}>
                             <div className="header-content">
                                 <div className="row">
                                   <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                 <div className="headericon">
                                 <img src={dm} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                  <div className="col-lg-10 col-md-12 col-sm-12">
                                  <div className="content mt-1">
                                     <div className="link-heading">IT Audit, Compliance, & Assurance</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                             </Link>
                             <Link to="/Service?section=Data Loss Prevention & Disaster Recovery" style={{ textDecoration: 'none'}}>
                               <div className="header-content mt-5">
                                 <div className="row">
                                 <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                   <div className="headericon">
                                 <img src={cb} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                   <div className="col-lg-10 col-md-12 col-sm-12">
                                   <div className="content mt-1">
                                     <div className="link-heading">Data Loss Prevention & Disaster Recovery</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                               </Link>
                             </div>
                             <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                             <Link to="/Service?section=Security Policy Development & Assessment" style={{ textDecoration: 'none'}}>
                               <div className="header-content">
                                 <div className="row">
                                 <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                   <div className="headericon">
                                 <img src={cw} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                   <div className="col-lg-10 col-md-12 col-sm-12">
                                   <div className="content mt-1">
                                     <div className="link-heading">Security Policy Development & Assessment</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                               </Link>
                               <Link to="/Service?section=CCTV Surveillance & Remote Monitoring Solution" style={{ textDecoration: 'none'}}>
                               <div className="header-content">
                                 <div className="row">
                                 <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                   <div className="headericon">
                                 <img src={se} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                   <div className="col-lg-10 col-md-12 col-sm-12">
                                   <div className="content mt-1">
                                     <div className="link-heading">CCTV Surveillance & Remote Monitoring Solution</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                               </Link>
                             </div>
                           </div>
                          }
                          {activeTab === "IT consulting" && 
                           <div className="row mt-2">
                           <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                             <Link to="/Service?section=Cloud Technology" style={{ textDecoration: 'none'}}>
                             <div className="header-content">
                                 <div className="row">
                                   <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                 <div className="headericon">
                                 <img src={dm} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                  <div className="col-lg-10 col-md-12 col-sm-12">
                                  <div className="content mt-1">
                                     <div className="link-heading">Cloud Technology</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                             </Link>
                             <Link to="/Service?section=Software Licensing & Procurement" style={{ textDecoration: 'none'}}>
                               <div className="header-content mt-5">
                                 <div className="row">
                                 <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                   <div className="headericon">
                                 <img src={cb} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                   <div className="col-lg-10 col-md-12 col-sm-12">
                                   <div className="content mt-1">
                                     <div className="link-heading">Software Licensing & Procurement</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                               </Link>
                             </div>
                             <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                             <Link to="/Service?section=Business Process & Outsourcing" style={{ textDecoration: 'none'}}>
                               <div className="header-content">
                                 <div className="row">
                                 <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                   <div className="headericon">
                                 <img src={cw} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                   <div className="col-lg-10 col-md-12 col-sm-12">
                                   <div className="content mt-1">
                                     <div className="link-heading">Business Process & Outsourcing</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                               </Link>
                               <Link to="/Service?section=Business Transformation & Automation" style={{ textDecoration: 'none'}}>
                               <div className="header-content">
                                 <div className="row">
                                 <div className="col-lg-2 col-md-12 com-sm-12">
                                   <div className="header-box">
                                   <div className="headericon">
                                 <img src={se} alt="Logo" className="w-100" />
                                 </div>
                                     </div>
                                   </div>
                                   <div className="col-lg-10 col-md-12 col-sm-12">
                                   <div className="content mt-1">
                                     <div className="link-heading">Business Transformation & Automation</div>
                                     <div className="link-subtext">Digital marketing is the promotion of products or services online through channels like social media, email, and search engines.</div>
                                     </div>
                                  </div>
                                   </div>
                               </div>
                               </Link>
                             </div>
                           </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/Product"
                >
                  Our Products
                </NavLink>
                <img src={navline} alt="Logo" className="active-indicator" />
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/Blog"
                >
                  Blog
                </NavLink>
                <img src={navline} alt="Logo" className="active-indicator" />
              </li>
            </ul>
            <div className="d-flex mr-35" role="search">
              <Link to="/Contact">
              <div
                    className={`button-7 property-1-5-${state.property1}`}
                    onMouseLeave={() => {
                      dispatch("mouse_leave");
                    }}
                    onMouseEnter={() => {
                      dispatch("mouse_enter");
                    }}
                  >
                    <div className="overlap-group-4">
                      <div className="text-wrapper-5">Book An Appointment</div>
                      {state.property1 === "default" && <img className="subtract-4" alt="Subtract" src={subtract} />}
                    </div>
                </div>
              </Link>
               
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
