import React, { useEffect, useReducer } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./OurProducts.css";
import highlight from "../assets/images/highlight.png";
import productcrypto from "../assets/images/productcrypto.png";
import productcommerce from "../assets/images/productcommerce.png";
import productinventory from "../assets/images/productinventory.png";
import productleave from "../assets/images/productleave.png";
import subtract from "../assets/images/subtract.svg";

export default function OurProduts() {
  const reducer = (state, action) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "mouse_enter":
        return {
          ...state,
          property1: "variant-2",
        };

      case "mouse_leave":
        return {
          ...state,
          property1: "default",
        };
    }

    return state;
  };

  const [state, dispatch] = useReducer(reducer, {
    property1: "default",
  });
  const [state1, dispatch1] = useReducer(reducer, {
    property1: "default",
  });
  const [state2, dispatch2] = useReducer(reducer, {
    property1: "default",
  });
  const [state3, dispatch3] = useReducer(reducer, {
    property1: "default",
  });

  useEffect(() => {
    // Initialize AOS
    AOS.init();

    return () => {
      AOS.refresh(); // Remove if you don't want to refresh on unmount
    };
  }, []);
  return (
    <div>
      <div className="container-fluid">
        <div className="row mt-2">
          <div className="new-heading">Our Products</div>
          <div className="sub-text mt-3">
            Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
            mi. Aliquam
          </div>
        </div>
      </div>
      <div class="container mt-5 pt-5 custom">
    <div class="row p-3 flex-column-reverse flex-sm-row" id="myRow">
        <div class="col-lg-6 col-md-12 col-sm-12" data-aos="fade-right" data-aos-duration="1500">
            <div className="sub-heading mt-3">Crypto Currency Transaction System</div>
            <div className="sub-para mt-3 hide-txt">
                Our tailored system is designed to meet the unique needs of
                businesses navigating the dynamic world of digital currencies.
                With expertise in blockchain technology and crypto transactions,
                our team offers a customized solution that ensures seamless and
                secure management of cryptocurrency transactions. Our system
                encompasses features such as real-time transaction tracking,
                secure wallet management, and comprehensive reporting tools.
            </div>
            <div>
                <button
                    className={`mt-3 button ${state.property1} `}
                    onMouseLeave={() => {
                        dispatch("mouse_leave");
                    }}
                    onMouseEnter={() => {
                        dispatch("mouse_enter");
                    }}
                >
                    <div className="overlap-group">
                        <div className="text-wrapper">Learn More</div>
                        {state.property1 === "default" && (
                            <img className="subtract" alt="Subtract" src={subtract} />
                        )}
                    </div>
                </button>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12" id="myCol">
            <div>
                <div className="main-img">
                    <img
                        src={productcrypto}
                        alt="photo"
                        className="full-width pt-4"
                    />
                </div>
                <div className="highlight">
                    <img src={highlight} alt="photo" className="full-width" />
                </div>
            </div>
        </div>
    </div>
</div>

      <div class="container">
        <div class="row p-3">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div>
              <div className="main-img">
                <img
                  src={productcommerce}
                  alt="photo"
                  className="full-width pt-4"
                />
              </div>
              <div className="highlight">
                <img src={highlight} alt="photo" className="full-width" />
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 col-md-12 col-sm-12"
            data-aos="fade-left"
            data-aos-duration="1500"
          >
            <div className="sub-heading mt-3">
              E - Commerce Management System
            </div>
            <div className="sub-para mt-3 hide-txt">
              Whether you're a startup or an established enterprise, our
              commitment is to empower your business with a scalable and
              flexible E-commerce solution that grows with your business and
              keeps you ahead in the ever-growing digital marketplace.
            </div>
            <div>
              <button
                className={`mt-3 button ${state1.property1} `}
                onMouseLeave={() => {
                  dispatch1("mouse_leave");
                }}
                onMouseEnter={() => {
                  dispatch1("mouse_enter");
                }}
              >
                <div className="overlap-group">
                  <div className="text-wrapper">Learn More</div>
                  {state1.property1 === "default" && (
                    <img className="subtract" alt="Subtract" src={subtract} />
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row p-3 flex-column-reverse flex-sm-row" id="myRow">
          <div
            class="col-lg-6 col-md-12 col-sm-12"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <div className="sub-heading mt-3">Inventory Management System</div>
            <div className="sub-para mt-3 hide-txt">
              No one-size-fits-all inventory? We get it. That's why our
              customized system bends to your specific business needs,
              seamlessly aligning with your unique workflow. Our solution
              ensures real-time visibility into stock levels, optimizing the
              entire inventory lifecycle beginning from procurement to sales.
            </div>
            <div>
              <button
                className={`mt-3 button ${state2.property1} `}
                onMouseLeave={() => {
                  dispatch2("mouse_leave");
                }}
                onMouseEnter={() => {
                  dispatch2("mouse_enter");
                }}
              >
                <div className="overlap-group">
                  <div className="text-wrapper">Learn More</div>
                  {state2.property1 === "default" && (
                    <img className="subtract" alt="Subtract" src={subtract} />
                  )}
                </div>
              </button>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12" id="myCol">
            <div>
              <div className="main-img">
                <img
                  src={productinventory}
                  alt="photo"
                  className="full-width pt-4"
                />
              </div>
              <div className="highlight">
                <img src={highlight} alt="photo" className="full-width" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row p-3">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div>
              <div className="main-img">
                <img
                  src={productleave}
                  alt="photo"
                  className="full-width pt-4"
                />
              </div>
              <div className="highlight">
                <img src={highlight} alt="photo" className="full-width" />
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 col-md-12 col-sm-12"
            data-aos="fade-left"
            data-aos-duration="1500"
          >
            <div className="sub-heading mt-3">E - Leave Management System</div>
            <div className="sub-para mt-3 hide-txt">
              Alpha Vision Technology provides a customized E-Leave Management
              System that helps businesses digitize their internal leave
              management procedures. Our system is meticulously designed to
              handle all stages of the employee leave process including
              approvals from individual managers to the HR department.
            </div>
            <div>
              <button
                className={`mt-3 button ${state3.property1} `}
                onMouseLeave={() => {
                  dispatch3("mouse_leave");
                }}
                onMouseEnter={() => {
                  dispatch3("mouse_enter");
                }}
              >
                <div className="overlap-group">
                  <div className="text-wrapper">Learn More</div>
                  {state3.property1 === "default" && (
                    <img className="subtract" alt="Subtract" src={subtract} />
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
