// import './AboutDescribe.css';
// import React, { useEffect, useState } from 'react';
// import AOS from 'aos';
// import './Team.css';
// import clientman from '../assets/images/man.png';
// import sublogo from '../assets/images/sublogo.png';
// import 'aos/dist/aos.css';
// import man from '../assets/images/man.jpg';

// export default function Team() {
//   const [expanded, setExpanded] = useState(false);
//   const [cardWidth, setCardWidth] = useState(150);

//   const toggleExpand = () => {
//     setExpanded(!expanded);
//     setCardWidth(expanded ? 150 : 400);
//   };

//   const revertToDefault = () => {
//     setExpanded(false);
//     setCardWidth(150);
//   };

  
//   useEffect(() => {
//     // Initialize AOS
//     AOS.init();

//     return () => {
//       AOS.refresh(); // Remove if you don't want to refresh on unmount
//     };
//   }, []);

//   return (
//     <div>
//       <div className='container mt-5'>
//         <div className='row'>
//         <div className="heading">Meet The Team</div>
//         <div className="sub-text mt-3">Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam</div>
//         </div>
//       </div>
//       {/* <div className='container mt-4'>
//       <div className='sub-box'>
//       <img src={sublogo} alt="person" className="w-100" />
//       <div className='logo-connect mt-5'></div>
//       </div>
//       </div>
//       <div className="container mt-5 pt-3">
//       <div className='full-border'></div>
//         <div className="row">
//             <div className='col-lg-3 col-md-6 col-sm-12'>
//               <div className='border-connect'></div>
//               <div className='team-box'>
//                 <div className='flex-image'>
//                   <div className='team-image'>
//                   <img src={man} alt="person" className="w-100" />
//                   </div>
//                   <div>
//                   <i class="fab fa-linkedin"></i>
//                   </div>
//                 </div>
//                 <div className='name mt-3'>Surya</div>
//                 <div className='role mt-2'>UI/UX Designer</div>
//               </div>
//             </div>
//             <div className='col-lg-3 col-md-6 col-sm-12'>
//             <div className='border-connect'></div>
//               <div className='team-box space-m'>
//                 <div className='flex-image'>
//                   <div className='team-image'>
//                   <img src={man} alt="person" className="w-100" />
//                   </div>
//                   <div>
//                   <i class="fab fa-linkedin"></i>
//                   </div>
//                 </div>
//                 <div className='name mt-3'>Surya</div>
//                 <div className='role mt-2'>UI/UX Designer</div>
//               </div>
//             </div>
//             <div className='col-lg-3 col-md-6 col-sm-12'>
//             <div className='border-connect'></div>
//               <div className='team-box space-s'>
//                 <div className='flex-image'>
//                   <div className='team-image'>
//                   <img src={man} alt="person" className="w-100" />
//                   </div>
//                   <div>
//                   <i class="fab fa-linkedin"></i>
//                   </div>
//                 </div>
//                 <div className='name mt-3'>Surya</div>
//                 <div className='role mt-2'>UI/UX Designer</div>
//               </div>
//             </div>
//             <div className='col-lg-3 col-md-6 col-sm-12'>
//             <div className='border-connect'></div>
//               <div className='team-box space-s'>
//                 <div className='flex-image'>
//                   <div className='team-image'>
//                   <img src={man} alt="person" className="w-100" />
//                   </div>
//                   <div>
//                   <i class="fab fa-linkedin"></i>
//                   </div>
//                 </div>
//                 <div className='name mt-3'>Surya</div>
//                 <div className='role mt-2'>UI/UX Designer</div>
//               </div>
//             </div>
//         </div>
//       </div>
//       <div className="container mt-4">
//         <div className="row">
//             <div className='col-lg-3 col-md-6 col-sm-12'>
//             <div className='border-connect'></div>
//               <div className='team-box'>
//                 <div className='flex-image'>
//                   <div className='team-image'>
//                   <img src={man} alt="person" className="w-100" />
//                   </div>
//                   <div>
//                   <i class="fab fa-linkedin"></i>
//                   </div>
//                 </div>
//                 <div className='name mt-3'>Surya</div>
//                 <div className='role mt-2'>UI/UX Designer</div>
//               </div>
//             </div>
//             <div className='col-lg-3 col-md-6 col-sm-12'>
//             <div className='border-connect'></div>
//               <div className='team-box space-m'>
//                 <div className='flex-image'>
//                   <div className='team-image'>
//                   <img src={man} alt="person" className="w-100" />
//                   </div>
//                   <div>
//                   <i class="fab fa-linkedin"></i>
//                   </div>
//                 </div>
//                 <div className='name mt-3'>Surya</div>
//                 <div className='role mt-2'>UI/UX Designer</div>
//               </div>
//             </div>
//             <div className='col-lg-3 col-md-6 col-sm-12'>
//             <div className='border-connect'></div>
//               <div className='team-box space-s'>
//                 <div className='flex-image'>
//                   <div className='team-image'>
//                   <img src={man} alt="person" className="w-100" />
//                   </div>
//                   <div>
//                   <i class="fab fa-linkedin"></i>
//                   </div>
//                 </div>
//                 <div className='name mt-3'>Surya</div>
//                 <div className='role mt-2'>UI/UX Designer</div>
//               </div>
//             </div>
//             <div className='col-lg-3 col-md-6 col-sm-12'>
//             <div className='border-connect'></div>
//               <div className='team-box space-s'>
//                 <div className='flex-image'>
//                   <div className='team-image'>
//                   <img src={man} alt="person" className="w-100" />
//                   </div>
//                   <div>
//                   <i class="fab fa-linkedin"></i>
//                   </div>
//                 </div>
//                 <div className='name mt-3'>Surya</div>
//                 <div className='role mt-2'>UI/UX Designer</div>
//               </div>
//             </div>
//         </div>
//       </div> */}

// <div className='d-flex'>
// <div className='card' style={{ width: cardWidth }}>
//       <div className='d-flex'>
//         <div className='img-container' onClick={toggleExpand}>
//           <img src={clientman} alt="Logo" className="w-100 hover-icon" />
//         </div>
//         {/* Expanded content always rendered */}
//         <div className='expand-content' style={{ display: expanded ? 'block' : 'none' }}>
//                  <div className='d-flex mt-3 mx-3'>
//                    <div className='team-logo'>
//                    <img src={sublogo} alt="person" className="w-100" />
//                    </div>
//                    <div>
//                    <i class="fab fa-linkedin"></i>
//                    </div>
//                  </div>
//            <div className='d-flex'>
//            <div>
//                 <div className='name mt-3'>Surya</div>
//          <div className='role mt-2'>UI/UX Designer</div>
//                 </div>
//            <div className='arrow' onClick={revertToDefault}>↑</div>
//            </div>
//         </div>
//       </div>
//     </div>
//     <div className='card' style={{ width: cardWidth }}>
//       <div className='d-flex'>
//         <div className='img-container' onClick={toggleExpand}>
//           <img src={clientman} alt="Logo" className="w-100 hover-icon" />
//         </div>
//         {/* Expanded content always rendered */}
//         <div className='expand-content' style={{ display: expanded ? 'block' : 'none' }}>
//                  <div className='d-flex mt-3 mx-3'>
//                    <div className='team-logo'>
//                    <img src={sublogo} alt="person" className="w-100" />
//                    </div>
//                    <div>
//                    <i class="fab fa-linkedin"></i>
//                    </div>
//                  </div>
//            <div className='d-flex'>
//            <div>
//                 <div className='name mt-3'>Surya</div>
//          <div className='role mt-2'>UI/UX Designer</div>
//                 </div>
//            <div className='arrow' onClick={revertToDefault}>↑</div>
//            </div>
//         </div>
//       </div>
//     </div>
//     <div className='card' style={{ width: cardWidth }}>
//       <div className='d-flex'>
//         <div className='img-container' onClick={toggleExpand}>
//           <img src={clientman} alt="Logo" className="w-100 hover-icon" />
//         </div>
//         {/* Expanded content always rendered */}
//         <div className='expand-content' style={{ display: expanded ? 'block' : 'none' }}>
//                  <div className='d-flex mt-3 mx-3'>
//                    <div className='team-logo'>
//                    <img src={sublogo} alt="person" className="w-100" />
//                    </div>
//                    <div>
//                    <i class="fab fa-linkedin"></i>
//                    </div>
//                  </div>
//            <div className='d-flex'>
//            <div>
//                 <div className='name mt-3'>Surya</div>
//          <div className='role mt-2'>UI/UX Designer</div>
//                 </div>
//            <div className='arrow' onClick={revertToDefault}>↑</div>
//            </div>
//         </div>
//       </div>
//     </div>
// </div>


//     </div>
//   );
// }



import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import './Team.css';
import clientman from '../assets/images/man.png';
import sublogo from '../assets/images/sublogo.png';
import 'aos/dist/aos.css';
import man from '../assets/images/man.jpg';

export default function Team() {
  const [expandedIndex, setExpandedIndex] = useState(-1); // Track the index of the expanded box, -1 means none expanded

  const toggleExpand = (index) => {
     setExpandedIndex(index === expandedIndex ? -1 : index); // Toggle the expanded state for the clicked box
  };

  useEffect(() => {
    // Initialize AOS
    AOS.init();

    return () => {
      AOS.refresh(); // Remove if you don't want to refresh on unmount
    };
  }, []);

  return (
    <div>
      <div className='container mt-5 pt-5'>
        <div className='row'>
          <div className="heading">Meet The Team</div>
          <div className="sub-text mt-3">Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam</div>
        </div>
      </div>

      <div className='container mt-5 pt-3'>
      <div className='total-cards'>
      <div className='d-flex'>
        {[1, 2, 3, 4, 5].map((index) => (
          <div key={index} className='card'  onClick={() => toggleExpand(index)} style={{ width: expandedIndex === index ? 400 : 150 }}>
            <div className='d-flex'>
              <div className='img-container'>
                <img src={clientman} alt="Logo" className="w-100 hover-icon" />
              </div>
              <div className='expand-content' style={{ display: expandedIndex === index ? 'block' : 'none' }}>
               <div className='content-expand'>
               <div className='d-flex mt-3 mx-3'>
                  <div className='team-logo'>
                    <img src={sublogo} alt="person" className="w-100" />
                  </div>
                  <div>
                    <i className="fab fa-linkedin"></i>
                  </div>
                </div>
                <div className='mt-5 px-3'>
                  <div>
                    <div className='name-team mt-3'>Surya</div>
                    <div className='role-team mt-3'>UI/UX Designer</div>
                  </div>
                </div>
                <div className='mt-5 pt-5'>
                <div className='arrow' onClick={() => toggleExpand(index)}>
                <i class="fa-solid fa-chevron-left"></i>
                </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      </div>
      </div>
    </div>
  );
}
