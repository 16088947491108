import React from 'react';
import './Search.css'; // Import your CSS file
import { FaSearch } from 'react-icons/fa'; // Import the search icon from a library like react-icons

const Search = () => {
  return (
   <div>
    <div className='container mt-5'>
        <div className='row'>
            <div className='col-lg-2'></div>
            <div className='col-lg-8'>
            <div className="search-bar">
      <div className="search-container">
        <input type="text" placeholder="Search the topic..." className='search-input' />
        <FaSearch className="search-icon" />
      </div>
    </div>
            </div>
            <div className='col-lg-2'></div>
        </div>
        
    </div>
   </div>
  );
};

export default Search;
